/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import React from 'react';  

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import CreateGroup from "layouts/dashboard/components/CreateGroup";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
import { Helmet } from "react-helmet";
import axios from 'axios';  
import { browserHistory, Router, Route } from 'react-router';
//import SignIn from "layouts/authentication/sign-in";
import { Navigate } from "react-router-dom";

export default class Dashboard extends React.Component {  
  constructor(props) {
    super(props);
    this.state = { loggedIn: '1'};
  }

  
  componentDidMount() {  
      
      axios.get(`http://localhost:9000/testAPIp`, { withCredentials: true })  
      .then(res => {  
        this.setState({loggedIn: res.data.loggedIn});
      });
      
  } 


  render() {  
  
  
  const { size } = typography;
  const { chart, items } = reportsBarChartData;
  
  
  if(this.state.loggedIn!=='1')
  {
    return <Navigate to="/sign-in" />

  };

  return (
    <DashboardLayout>
      <Helmet>
      <title>Dashboard | {global.config.app.name}</title>
      <meta
        name="description"
    content="Join Our Cricket Team - Experience the thrill of competitive cricket and be part of a supportive community. Sign up now and showcase your skills on the field."
      />
      <meta
        name="keywords"
        content="Cricket, team, join, competitive, skills, community, sign up, field"
      />
    </Helmet>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total Groups" }}
                count="4"
                percentage={{ color: "success", text: "" }}
                icon={{ color: "info", component: "public" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Owned Groups" }}
                count="1"
                percentage={{ color: "success", text: "" }}
                icon={{ color: "info", component: "account_balance" }}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <CreateGroup />
            </Grid>
            
          </Grid>
        </SoftBox>
        
        
      </SoftBox>
      <Footer />
    </DashboardLayout>
  )
  }
}