/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useEffect, useState, useRef} from 'react';
import axios from 'axios';  

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import { Helmet } from "react-helmet";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";
import React from 'react';  
import RECAPTCHA from "react-google-recaptcha"

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";
export default class Contact extends React.Component {  
  constructor(props) {
    super(props);
    axios.defaults.withCredentials = true;
    this.ref_full_name = React.createRef();
    this.ref_mobile_no = React.createRef();
    this.ref_password = React.createRef();
    this.ref_email = React.createRef();
    this.captchaRef= React.createRef();
    
    this.ref_error_msg = React.createRef();
    //this.ref_full_name.current = '';
    this.setState({error_msg: ''});
    
  }

  state = {  
    viewstatus: 'signup'  
  }  


    
  componentDidMount() {  
        this.setState({viewstatus: 'signup'});
        
  }  
  onClickSignupVerify= (e)=> {
    this.setState({error_msg: ''});
    const otp = document.getElementById('otp').value
    
    let post_data = JSON.stringify({
      otp: otp
    });

    //const post_data = { mobile_no: mobile_no };
    
    axios.post(global.config.app.backend_api_url +`login/signupverify`, post_data, { withCredentials: true,
      headers: {
        'content-type': 'application/json', 
     } })  
      .then(res => {  
        console.log(res);
        alert(res.data.message);
        if(res.data.status=='1')
        {
          window.location.replace('groups');
        }
      }).catch((error) => {
        // Error
        console.log('response: ', error.response.data);
        this.ref_error_msg.current  = error.response.data.message;
        this.setState({error_msg: error.response.data.message});
        //alert("Errror")
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the 
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);
    });

      
    }

  onClickSignup = (e)=> {
    this.setState({error_msg: ''});
    const full_name = document.getElementById('full_name').value
    const mobile_no = document.getElementById('mobile_no').value
    const password = document.getElementById('password').value
    const email= document.getElementById('email').value
    this.ref_full_name.current  = full_name;
    this.ref_mobile_no.current  = mobile_no;
    this.ref_password.current  = password;
    this.ref_email.current  = email;
    

    const token = this.captchaRef.current.getValue();
    this.captchaRef.current.reset();

    let post_data = JSON.stringify({
      mobile_no: mobile_no,
      password : password,
      email : email,
      name : full_name,
      gtoken : token
    });


    

    //const post_data = { mobile_no: mobile_no };
    
    axios.post(global.config.app.backend_api_url +`login/signupcheck`, post_data, { withCredentials: true,
      headers: {
        'content-type': 'application/json', 
     } })  
      .then(res => {  
        console.log(res);
        alert(res.data.message);
        if(res.data.status=='1')
        {
          this.setState({viewstatus: 'verify_otp'});
        }
      }).catch((error) => {
        // Error
        console.log('response: ', error.response.data);
        this.ref_error_msg.current  = error.response.data.message;
        this.setState({error_msg: error.response.data.message});
        //alert("Errror")
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the 
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);
    });

      
    }
  

  render() {  

//    const [agreement, setAgremment] = useState(true);
 //   const [viewsection, setViewsection] = useState(1);
  
   // const handleSetAgremment = () => setAgremment(!agreement);
  


    console.log("sess_data:");  
    console.log(this.state.viewsection);  

    return (  
      <BasicLayout
      title="Welcome!"
      description="Join the Cricket Squad! Are you a cricket enthusiast looking for a team to play with?  "
      image={curved6}
    >
       <Helmet>
      <title>Sign Up | {global.config.app.name}</title>
      <meta
        name="description"
    content="Join Our Cricket Team - Experience the thrill of competitive cricket and be part of a supportive community. Sign up now and showcase your skills on the field."
      />
      <meta
        name="keywords"
        content="Cricket, team, join, competitive, skills, community, sign up, field"
      />
    </Helmet>

    {this.state.viewstatus === 'signup' ? (
    <Card>
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Register 
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form">
            <SoftBox mb={2}>
              <SoftInput placeholder="Name" name="full_name" id="full_name" inputProps={{ maxLength: 60}}  />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="text" name="mobile_no" id="mobile_no" placeholder="Mobile No" inputProps={{ maxLength: 10}} />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="email" name="email" id="email" placeholder="Email" inputProps={{ maxLength: 70}} />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="password" name="password" id="password" placeholder="Password" inputProps={{ maxLength: 20}}  />
            </SoftBox>
            <SoftBox display="flex" alignItems="center">
              <Checkbox  />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </SoftTypography>
              <SoftTypography
                component="a"
                href="/termsandconditions"
                variant="button"
                fontWeight="bold"
                target="_blank"
                textGradient
              >
                Terms and Conditions
              </SoftTypography>
            </SoftBox>
            <SoftBox mb={2}>
            <RECAPTCHA sitekey="6LehUMomAAAAAJl1yE63intOIHyqyPNwpqUQZ_gG"
            ref={this.captchaRef }

            />
            </SoftBox>

            <SoftBox mt={4} mb={1}>

            <SoftTypography
                component="span"
                fontWeight="regular"
                
              >
                {this.state.error_msg}
              </SoftTypography>

              <SoftButton variant="gradient" color="dark" fullWidth onClick={this.onClickSignup}>
                sign up
              </SoftButton>
            </SoftBox>
            <SoftBox mt={3} textAlign="center">
              <SoftTypography variant="button" color="text" fontWeight="regular">
                Already have an account?&nbsp;
                <SoftTypography
                  component={Link}
                  to="/sign-in"
                  variant="button"
                  color="dark"
                  fontWeight="bold"
                  textGradient
                >
                  Sign in
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    ):( <Card>
      <SoftBox p={3} mb={1} textAlign="center">
        <SoftTypography variant="h5" fontWeight="medium">
          Verify OTP 
        </SoftTypography>

        <SoftBox mb={2}>
              <SoftInput type="text" name="otp" id="otp" placeholder="Please enter OTP" inputProps={{ maxLength: 4}} />
            </SoftBox>

      </SoftBox>

      <SoftButton variant="gradient" color="dark" fullWidth onClick={this.onClickSignupVerify}>
                Verify
              </SoftButton>

      </Card>
    )
    }
      </BasicLayout>
    )  
  }  
}  
