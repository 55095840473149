/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import axios from 'axios';  
import React from 'react';  
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { useLocation, Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";


// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import { useState, useEffect } from "react";

// @mui material components
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard React components
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard React examples
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React icons
import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import Settings from "examples/Icons/Settings";

// Soft UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import curved0 from "assets/images/curved-images/curved0.jpg";

import { Helmet } from "react-helmet";

export default class ProfileOverview extends React.Component {  

  constructor(props) {
    super(props);
    this.setState({loggedIn: '1'});
    this.setState({showModal: false});
    

    
    this.state = { loggedIn: '1',showModal: false,viewstatus:'list',linkrows:[],groupname:'Cricket Group',group_normal_count: 14, group_guest_count : 2};

    let search = window.location.search;
    let params = new URLSearchParams(search);

    let sid = params.get('sid')
    if(sid==null)
    {
      sid=  '';
    }
    let series_id = '';
    if(sid!=''){
      series_id = atob(sid);
    }
    if(series_id  !='')
    {
     
      setTimeout(
        () => this.setState({viewstatus: 'createlink'}), 
        1500
      );
      //this.setState({viewstatus: 'createlink'});
    }
    
//    this.setState({linkData:{rows:[]}});

  this.ref_link_heading = React.createRef();

  this.ref_link_heading_link = React.createRef();
    
  }


  componentDidMount() { 
    /*
  axios.get(global.config.app.backend_api_url +`groups/setsession?user_id=2`, { withCredentials: true })//, { withCredentials: true }  
    .then(res => {  

    });*/
    const post_data1 = { lo : 1};

    axios.post( global.config.app.backend_api_url + `login/sessionavailable`,post_data1, { withCredentials: true }
    )  
      .then(res => {  
        console.log(res.data)
        var user_id = res.data.user_details.user_id;
        
        if (user_id !== undefined && user_id !== null && user_id !== 'null') 
        {
        }else
        {
          window.location.replace('sign-in');
          //return;
        }
  
      });
  
    const post_data = { lo: 1 };
    
 //  alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
  axios.post( global.config.app.backend_api_url + `members/getprofile`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("get profile")
      console.log(res.data)
      this.setState( {full_name:res.data.profile[0].full_name,mobile_no:res.data.profile[0].mobile_no,profile_pic:res.data.profile[0].profile_pic,cric_heroes_profile_link:res.data.profile[0].cric_heroes_profile_link});

     document.getElementById('p_cric_heroes_profile_link').value  =res.data.profile[0].cric_heroes_profile_link;
     document.getElementById('p_full_name').value = res.data.profile[0].full_name;
     



      

    });
    /*
    axios.get(global.config.app.backend_api_url +`testAPIp`, { withCredentials: true })  
    .then(res => {  
      this.setState({loggedIn: res.data.loggedIn, designation: "your value"});

    });*/

}  


onClickUpdate = (e)=> {

  
  var p_full_name = document.getElementById('p_full_name').value
  var p_cric_heroes_profile_link = document.getElementById('p_cric_heroes_profile_link').value
  var p_email = document.getElementById('p_email').value
  
  const post_data = {full_name:p_full_name,cric_heroes_profile_link:p_cric_heroes_profile_link,email:p_email};
    console.log(post_data)
//      alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
  axios.post( global.config.app.backend_api_url + `members/updateprofile`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        this.setState({ full_name : p_full_name, viewstatus: 'list'});
      }

    });


  }

  onClickUpdatePassword = (e)=> {

  
    var p_password = document.getElementById('p_password').value
    var p_confirm_password = document.getElementById('p_confirm_password').value
    
    
    const post_data = {password:p_password,confirm_password:p_confirm_password};
      console.log(post_data)
  //      alert(group_id)
  //,{headers: {"Content-Type": "application/json"}}
    axios.post( global.config.app.backend_api_url + `members/changepassword`,post_data, { withCredentials: true }
    )  
      .then(res => {  
        //const normalmembers = res.data;  
        console.log("after112")
        console.log(res.data)
        if(res.data.status=='0')
        {
          alert(res.data.message);
        }
        else
        {
          alert(res.data.message);
          this.setState({ viewstatus: 'list'});
        }
  
      });
  
  
    }

  onImageChange = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
     // alert(img)

      const formData = new FormData();
      formData.append("profile_image", event.target.files[0]);
      //call API

      axios.post( global.config.app.backend_api_url + `members/uploadprofilepic`,formData, { withCredentials: true }
      )  
        .then(res => {  
          //const normalmembers = res.data;  
          console.log("after112")
          console.log(res.data)
          if(res.data.status=='0')
          {
            alert(res.data.message);
          }
          else
          {
            alert(res.data.message);
            //alert(res.data.profile_pic)
            /*
            this.setState({
              image: URL.createObjectURL(img)
            });
            */
            this.setState({ profile_pic : res.data.profile_pic, viewstatus: 'list'});

          }
    
        });

        

     
    }
  };

  render() {  
    return (  
    <DashboardLayout>
       <Helmet>
      <title>Profile | {global.config.app.name}</title>
      <meta
        name="description"
    content="Join Our Cricket Team - Experience the thrill of competitive cricket and be part of a supportive community. Sign up now and showcase your skills on the field."
      />
      <meta
        name="keywords"
        content="Cricket, team, join, competitive, skills, community, sign up, field"
      />
    </Helmet>
      
      

    <SoftBox position="relative">
      <DashboardNavbar absolute light />
      <SoftBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${curved0})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <SoftAvatar
              src={this.state.profile_pic ? (this.state.profile_pic):(burceMars)}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {this.state.full_name}
              </SoftTypography>
              <SoftTypography variant="button" color="text" fontWeight="medium">
              {this.state.mobile_no}
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                
                sx={{ background: "transparent" }}
              >
                {/*<Tab label="App" icon={<Cube />} />*/}
                <Tab label="Edit" onClick={() => this.setState({viewstatus: 'edit'})} icon={<Document />} />
                <Tab label="Set Pic" onClick={() => this.setState({viewstatus: 'uploadprofilepic'})} icon={<Cube />} />
                <Tab label="Change Password" onClick={() => this.setState({viewstatus: 'changepassword'})} icon={<Settings />} />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </SoftBox>


      <SoftBox mt={6} mb={6}>
        <Grid  lg={6} md={6} spacing={3}>
        {
                (() => {
                  if(this.state.viewstatus === 'edit' ){ 



                    const post_data = { lo: 1 };
    
                    //  alert(group_id)
                   //,{headers: {"Content-Type": "application/json"}}
                     axios.post( global.config.app.backend_api_url + `members/getprofile`,post_data, { withCredentials: true }
                     )  
                       .then(res => {  
                         //const normalmembers = res.data;  
                         
                        document.getElementById('p_cric_heroes_profile_link').value  =res.data.profile[0].cric_heroes_profile_link;
                        document.getElementById('p_full_name').value = res.data.profile[0].full_name;
                        document.getElementById('p_email').value = res.data.profile[0].email;
                        

                        
                   
                   
                   
                         
                   
                       });

                    return (
                      <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Edit Profile
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" id="groupform">
            
            <SoftBox mb={2}>
              <SoftInput type="text" name="p_full_name" id="p_full_name"  placeholder="Member Name" inputProps={{ maxLength: 60}} />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="email" name="p_email"  id="p_email" placeholder="Email" inputProps={{ maxLength: 70}} />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="text" name="p_cric_heroes_profile_link"  id="p_cric_heroes_profile_link" placeholder="CricHeroes Profile Link" inputProps={{ maxLength: 200}} />
            </SoftBox>
            
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" onClick={this.onClickUpdate}  color="dark" fullWidth >
                Update
              </SoftButton>
            </SoftBox>
            
          </SoftBox>

          <Link to="/profile">
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    Back 
                  </SoftTypography>
                
              </Link>
             

        </SoftBox>

          </Card>
                    )
                    
                  }
                  else if(this.state.viewstatus === 'changepassword' ){ 


                    return (
                      <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Change Password
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" id="groupform">
            
            <SoftBox mb={2}>
              <SoftInput type="password" name="p_password" id="p_password"  placeholder="Enter new password" inputProps={{ maxLength: 12}} />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="password" name="p_confirm_password" id="p_confirm_password"  placeholder="Confirm password" inputProps={{ maxLength: 12}} />
            </SoftBox>
            
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" onClick={this.onClickUpdatePassword}  color="dark" fullWidth >
                Update password
              </SoftButton>
            </SoftBox>
            
          </SoftBox>

          <Link to="/profile">
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    Back 
                  </SoftTypography>
                
              </Link>
             

        </SoftBox>

          </Card>
                    )
                    
                  }
                  else if(this.state.viewstatus === 'uploadprofilepic' ){
                   
                    return (
                      <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Change Profile Pic
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" id="groupform">
            
          <SoftBox mb={2}>
             <SoftInput type="file" name="p_profile_pic" id="p_profile_pic" placeholder="Profile pic" onChange={this.onImageChange}  />
 
           
            </SoftBox>
            
          </SoftBox>

          <Link to="/profile">
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    Back 
                  </SoftTypography>
                
              </Link>
             

        </SoftBox>

          </Card>
                    )
                    
                }

              }
              )()
                
                }

          
        </Grid>
      </SoftBox>
      <SoftBox mb={3}>
        
      </SoftBox>

      <Footer />
    </DashboardLayout>
  )
}

}