/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import React from 'react';  


// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import { Helmet } from "react-helmet";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";
import ReactGA from "react-ga";
import axios from 'axios';  

export default class HomePage extends React.Component {  
  
  constructor(props) {
    //const title_caption = "Welcome to " + global.config.app.name;

    super(props);
    axios.defaults.withCredentials = true;
    this.ref_full_name = React.createRef();
    this.ref_mobile_no = React.createRef();
    this.ref_password = React.createRef();
    this.ref_email = React.createRef();
    this.captchaRef= React.createRef();
    
    this.ref_error_msg = React.createRef();
    //this.ref_full_name.current = '';
    this.setState({error_msg: '',title_caption:"Welcome to " + global.config.app.name});
    
  }
  componentDidMount() {  
  ReactGA.pageview(window.location.pathname);

  const post_data = { lo : 1};

  axios.post( global.config.app.backend_api_url + `login/sessionavailable`,post_data, { withCredentials: true }
  )  
    .then(res => 
    {  
      console.log(res.data)
      var user_id = res.data.user_details.user_id;
      if (user_id !== undefined && user_id !== null && user_id !== 'null') 
      {
        window.location.replace('groups');
      }
    });
  }





render() {  
  return (

    
    <BasicLayout
      title='Welcome to Cricket Group'
      description="Welcome to 4cr.in, your ultimate destination for cricket enthusiasts. 4cr stands for 'For Cricket' and our website is dedicated to facilitating cricket group participations. Whether you're an individual, a group, or a club, our platform allows you to effortlessly create and manage match schedules while keeping everyone informed through timely notifications. With our unique match invite links, inviting and joining members becomes a breeze. Join us at 4cr.in and take your cricket experience to new heights!"
      image={curved6}
    >
      <Helmet>
      <title>{global.config.app.name}</title>
      <meta
        name="description"
    content="Join Our Cricket Group - Experience the thrill of competitive cricket and be part of a supportive community. Sign up now and showcase your skills on the field."
      />
      <meta
        name="keywords"
        content="Cricket, team, join, competitive, skills, community, sign up, field"
      />
    </Helmet>
    </BasicLayout>
  )
   
}  
}  