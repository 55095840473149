/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import axios from 'axios';  
import React from 'react';  
import {  Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import SoftInput from "components/SoftInput";

import gr_logo from "assets/images/group.png";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Helmet } from "react-helmet";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbarNotLooged from "examples/Navbars/DashboardNavbarNotLooged";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { useState, useRef} from 'react';
import Button from 'react-bootstrap/Button';
import {getPlayerProfile} from 'Utils.js'

import Modal from 'react-modal';
import { FaMinusCircle } from "react-icons/fa";
import { FaPenSquare } from "react-icons/fa";
import { FaBackward } from "react-icons/fa";
import { FaThumbsUp } from "react-icons/fa";



//import Modal from 'react-bootstrap/Modal';

// Data
//import membersTableData from "layouts/groups/data/membersTableData";

import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { Navigate } from "react-router-dom";
import SoftButton from "components/SoftButton";
import Checkbox from "@mui/material/Checkbox";

import whatsapp_logo from "assets/images/logos/whatsapp.png";
import telegram_logo from "assets/images/logos/telegram.png";
import loader_img from "assets/images/loader.svg";

import 'App.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {zIndex: 100000}   
};

const Spinner = () => (
  <img src={loader_img} class="zoom2" height="30"/>
)
function createsearchlist({ list }) {
  alert('here.')

}
//(iloop+1) + '. '+ full_name +' ( '  + mobile_no + " ) <a href='javascript:void(0)' onClick=window.clickReslink()>Add</a><br>"

function Author({ image, name, email }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column" >
        <SoftTypography variant="button" fontWeight="medium" color="info">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};
function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
};


export default class Mi extends React.Component {  
  constructor(props) {
    super(props);
    this.setState({loggedIn: '1'});
    this.setState({showModal: false});
    this.setState({viewstatus: 'list'});
    this.ref_j_mobile_no = React.createRef();
    window.helloComponent = this;
    



    this.state = { loggedIn: '1',showModal: false,viewstatus:'list',linkrows:[],groupname:'Friends Group',group_logo:team2,current_player_profile:'<h4>Player details</h4>'};
//    this.setState({linkData:{rows:[]}});
    
  }
  state = {  
    normalmembers: []  ,
    guestmembers:[]

  }  

  clickedAddMember(val_id){
   // alert("Called from outside"+val_id);

    document.getElementById('j_member_id').value    = val_id;
    this.onClickAddLink();
 }
    
 showModal (group_fk,user_fk) {

  let search = window.location.search;
    let params = new URLSearchParams(search);
    let invite_code = params.get('i');

  getPlayerProfile(group_fk,user_fk,'i_profile_modal',invite_code);
  //call API i_profile_modal
  //alert(group_fk + ' ' + user_fk)
  this.setState({
    show: !this.state.show
  });
};

  closeModal = e => {
    this.setState({
      show: false
    });
  
};

  componentDidMount() { 
    
      /*
    axios.get(global.config.app.backend_api_url +`groups/setsession?user_id=2`, { withCredentials: true })//, { withCredentials: true }  
      .then(res => {  

      });*/


      /*
      axios.get(global.config.app.backend_api_url +`testAPIp`, { withCredentials: true })  
      .then(res => {  
        this.setState({loggedIn: res.data.loggedIn, designation: "your value"});

      });*/
      this.getbasicajax();

      

  }  

  getbasicajax()
  {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let invite_code = params.get('i');
//    alert(invite_code)
    const post_data = { invite_code: invite_code };
    
//      alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
  axios.post( global.config.app.backend_api_url + `matchschedule/getbyinvite_code_forpay`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      
      this.setState( {user_id: res.data.user_id,groupname:res.data.link_data[0].group_name,group_admin_flag : res.data.link_data[0].is_admin
        ,series_id : res.data.link_data[0].series_id,series_name : res.data.link_data[0].series_name
        ,match_date : res.data.link_data[0].match_date
        ,match_date_name:res.data.link_data[0].match_date_name
        ,show_join_link_group_members_only:res.data.link_data[0].show_join_link_group_members_only
        ,from_time : res.data.link_data[0].from_time
        ,to_time : res.data.link_data[0].to_time
        ,header_description : res.data.link_data[0].header_description
        ,footer_description : res.data.link_data[0].footer_description
        ,total_participants : res.data.link_data[0].total_participants
        ,total_waiting_list : res.data.link_data[0].total_waiting_list
        ,add_delete_permission_cutoff_time : res.data.link_data[0].add_delete_permission_cutoff_time
        ,group_id : res.data.link_data[0].group_id
        ,joined_flag : res.data.link_data[0].joined_flag
        ,remaining_slots : res.data.link_data[0].remaining_slots
        ,status_name : res.data.link_data[0].status_name
        ,group_logo : res.data.link_data[0].group_logo}
        );

        this.setState( {
          og_title : res.data.link_data[0].group_name + ' - '+res.data.link_data[0].series_name + ' - '+res.data.link_data[0].match_date,
          og_description : res.data.link_data[0].remaining_slots +' slots remaining at '+ res.data.link_data[0].group_name + ' - '+res.data.link_data[0].series_name + ' - '+res.data.link_data[0].match_date
        });

      const normalmembers = this.createMemberRows(res.data.players_list);
      console.log("getting response "+res.data.players_list)
      this.setState({ normalmembers });  
      
      const wl_members = this.createMemberRows(res.data.wl_players_list);
      console.log("getting wl_members response "+res.data.wl_players_list)
      this.setState({ wl_members });  

      if(res.data.user_details.user_id!='')
        {
          this.callNotificationSubscribe(res.data.user_details.nai,res.data.user_details.user_id);
        }

        var share_content = ''
      
      //alert(this.state.group_admin_flag)
      


      

    });
  }
 

  callNotificationSubscribe(appid,userid)
  {
    if(userid=='' || appid=='')
    {
      return;
    }
    return;
    const s = document.createElement("script")
    s.src = "https://notix.io/ent/current/enot.min.js"
    s.onload = (sdk) => {
        sdk.startInstall({
            appId: appid,
            loadSettings: true,
            disableConsoleDebug: true,
            user: userid
        });

    }
    document.head.append(s);
  }


  clickReslink = (e)=> {

    alert('here1')
  //document.getElementById('j_member_id').value;
//  document.getElementById('j_member_add_btn').click();
  }

 onClickGenerateLink = (e)=> {
  alert('API to be called');
    this.setState({viewstatus: 'invite_link'});
  }
  onKeyDownSearch = (e)=> {

    const j_mobile_no = document.getElementById('j_mobile_no').value

    document.getElementById('j_member_id').value    = '';
    this.ref_j_mobile_no.current  = j_mobile_no;

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let invite_code = params.get('i');
    

    const post_data = { mobile_no: j_mobile_no,invite_code : invite_code };
      
//      alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
    axios.post( global.config.app.backend_api_url + `members/search`,post_data, { withCredentials: true }
    )  
      .then(res => {  
        //const normalmembers = res.data;  
        console.log("after112")
        
        
        document.getElementById("j_members_list").innerHTML=  '';
        var html_content = '';
        if(res.data.status!='0')
        {
          console.log(res.data)
          //const normalmembers = this.createMemberRows(res.data);
          var iloop ;
          for(iloop=0; iloop < res.data.length; iloop++){
              const full_name = res.data[iloop].full_name;
              const mobile_no = res.data[iloop].mobile_no;
              var m_user_id = res.data[iloop].user_id;
              //console.log(full_name)

              

              //html_content = html_content + createsearchlist(full_name)
              html_content = html_content + (iloop+1) + '. '+ full_name +' ( '  + mobile_no + " ) <a href='javascript:void(0)' onclick='window.helloComponent.clickedAddMember(&#39;"+m_user_id+"&#39;)'>Add</a><br>";

          }

          document.getElementById("j_members_list").innerHTML=html_content;
          const links_columns1 = [{ name: "full_name", align: "left" },
          { name: "mobile_no", align: "left" }];

         


        }else
        {
          document.getElementById("j_members_list").innerHTML=  'No members found.';

        }
        


        

      });

    

//    alert('API to be called..'+this.ref_j_mobile_no.current);
  }
  onClickShareWhatsapp= (e)=> {
    var share_content = ';';
    var url = "https://wa.me/?text="+share_content;
    window.open(url);
  }
  onClickNotify = (e)=> {

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let invite_code = params.get('i');


    const post_data = { invite_code : invite_code };
    console.log(post_data)
//      alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
  axios.post( global.config.app.backend_api_url + `matchschedule/sendnotification`,post_data, { withCredentials: true }
  )  
    .then(res => {  

      
      alert(res.data.message);

    });



  }
  backButtonClick = (e)=> {
    this.getbasicajax();
    this.setState({viewstatus: 'list'});
    
  }

    onClickAddLink = (e)=> {
      this.setState({loader_join: true});
      var j_mobile_no = document.getElementById('j_mobile_no').value
      this.ref_j_mobile_no.current  = j_mobile_no;
      var j_member_id = '';

      var j_keep_checked = document.getElementById('j_keep').checked;

      var j_full_name = '';
      try{
        j_full_name = document.getElementById('j_full_name').value
        
      }catch(Err)
      {
      }

      try{
        j_member_id = document.getElementById('j_member_id').value        
      }catch(Err)
      {
      }



  
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let invite_code = params.get('i');
      
  
      const post_data = { member_mobile_no: j_mobile_no,invite_code : invite_code,member_name:j_full_name,member_id:j_member_id };
        console.log(post_data)
  //      alert(group_id)
  //,{headers: {"Content-Type": "application/json"}}
      axios.post( global.config.app.backend_api_url + `matchschedule/add_participant`,post_data, { withCredentials: true }
      )  
        .then(res => {  
          //const normalmembers = res.data;  
          console.log("after112")
          console.log(res.data)
          if(res.data.status=='0')
          {
            alert(res.data.message);
          }
          else
          {
            alert(res.data.message);
            document.getElementById('j_mobile_no').value = '';
            try{
              document.getElementById('j_full_name').value  =''
              
            }catch(Err)
            {
            }
      
            try{
              document.getElementById('j_member_id').value     =''   
            }catch(Err)
            {
            }

            
            if(!j_keep_checked)
            {
            this.getbasicajax();
            this.setState({viewstatus: 'list'});
            }
          }
          this.setState({loader_join: false});

        });
  
    

   // alert('API to be called');
  }

  deleteRow(id, e){  
    //alert("deleted id : "+ id);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let invite_code = params.get('i');

    console.log( this.state.normalmembers);
    //const normalmembers = this.state.normalmembers.filter(item => item.id !== id);  
    //this.setState({ normalmembers }); 
    
    const post_data = { invite_code: invite_code,series_participant_id:id };
     
    axios.post( global.config.app.backend_api_url + `matchschedule/remove_participant`,post_data, { withCredentials: true }
    )  
      .then(res => {  
        //const normalmembers = res.data; 
        if(res.data.status=='0')
        {
          alert(res.data.message);
        } 
        else{
          alert(res.data.message);
        window.location.reload();
        }
  

        

      });


    
  }  

  membersTableColumns = {
    member_columns : [
      { name: "slno",align: "left" },
      { name: "member_name",align: "left" },
      { name: "action",align: "left" },
    ]
  }
  
  linkTableColumns = {
    links_columns: [
      { name: "invitelink", align: "left" },
      { name: "max_join_limit_with_link", align: "left" },
      { name: "current_join_count", align: "left" },
      { name: "link_expiry", align: "left" },
      { name: "action", align: "left" },
    ]
  };

  linkTableRows = {
    linkrows: [
      {
        invitelink: <Author image={team2} name="https://cricketgroups.in/sdfdsf"  />,
        max_join_limit_with_link: <Function job="10"  />,
        current_join_count: <Function job="1"  />,
        link_expiry: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            23/12/2023
          </SoftTypography>
        ),
        action: (
          <SoftBox>


                  

</SoftBox>

        ),
      }
      
    ],
  };


   membersTableData = {
    columns: [
      { name: "slno", align: "left" },
      { name: "partcipant_name", align: "left" },
      { name: "action", align: "left" },
    ],
  
    rows: [
      {
        slno : <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        1
      </SoftTypography>,
        member_name: <Author image={team2} name="Friends Group"  />,
        location: <Function job="Trivandrum"  />,
        memberscount: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            25
          </SoftTypography>
        ),
        added_on: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            12/11/2022
          </SoftTypography>
        ),
        action: (
          <SoftBox>
          <SoftTypography
            component="a"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            <FaMinusCircle></FaMinusCircle>
          </SoftTypography>
</SoftBox>

        ),
      }
      
    ],
  };

  createMemberRows(res)
{
  //loop here and create rows in soft UI rows
  console.log(res)
  const plainObj = [];

  var iloop ;
  for(iloop=0; iloop < res.length; iloop++){
      const thisid = res[iloop].id;
      const g_user_fk = res[iloop].series_participant_reg_user_fk;
      const g_group_fk = res[iloop].group_fk;
      const series_participant_id= res[iloop].series_participant_id;
      const href_link = "/viewmembers/"+thisid;
      //loopData += `<li>${data[i].name}</li>`
      const arrayLike = {
        slno: <Function job={iloop+1}  />,
        member_name: (
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={res[iloop].profile_pic ? (res[iloop].profile_pic):(team2)} alt={res[iloop].player_name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column" >
        <SoftTypography component="a" href="javascript:void(0)"  onClick={() => this.showModal(g_group_fk,g_user_fk)} variant="button" fontWeight="medium" color="info">
        {res[iloop].player_name} | {res[iloop].mobile_no}
        </SoftTypography>
        
      </SoftBox>
    </SoftBox>
),
        memberscount: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            25
          </SoftTypography>
        ),
        'added_on': res[iloop].added_on,
        'action' : (

          <SoftBox>
          
          <FaThumbsUp></FaThumbsUp>

          {
                (() => {

                  if(res[iloop].del_flag == '1')
                  {
                     return (

              
          <SoftTypography
          component="a"
          href="javascript:void(0)"
          variant="caption"
          color="secondary"
          title="Delete"
          fontWeight="medium"
          onClick={() => this.deleteRow(series_participant_id)}
        >
         <FaMinusCircle></FaMinusCircle>
        </SoftTypography>

                     )
                     }
                    })()
          
                  }


          

          </SoftBox>
          
        ),
        'id' : res[iloop].id
      };
      Array.prototype.push.call(plainObj, arrayLike);

  }
  
    //const myObjStr = JSON.stringify(plainObj);
    console.log("obj:")
    console.log(plainObj)
    console.log("res:")
    console.log(res)
    
    return plainObj;
  }

  
  

  render() {  

    const handleClose = () => {
      this.setState({showModal: false});
    }
    const handleShow = () => {
      this.setState({showModal: true});
    }


  const { columns, rows } = this.membersTableData;

  const {links_columns} = this.linkTableColumns;

  const {linkrows} = this.linkTableRows;
  const {member_columns} = this.membersTableColumns;
  //const [list, setList] = useState([]);
  //const [actuallist, setActuallist] = useState([]);

    console.log("sess_data:");  
    console.log(this.state.loggedIn);  

    return (  
      <DashboardLayout>
      <Helmet>
      <title>Match Invite | {global.config.app.name}</title>
      <meta
        name="description"
    content="Join Our Cricket Team - Experience the thrill of competitive cricket and be part of a supportive community. Sign up now and showcase your skills on the field."
      />
      <meta
        name="keywords"
        content="Cricket, team, join, competitive, skills, community, sign up, field"
      />
      <meta property="og:title" content={this.state.og_title} />
      <meta property="og:description" content={this.state.og_description}/>
    </Helmet>
      <DashboardNavbarNotLooged />
      <SoftBox py={3}>
      <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftAvatar
              src={this.state.group_logo ? (this.state.group_logo):(gr_logo)}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
            />
            {this.state.groupname} 
              

              <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              

{
                (() => {
                  let search = window.location.search;
                    let params = new URLSearchParams(search);
                    let group_id = params.get('i');

                  if(this.state.group_admin_flag === '1' ){ 

                  var link_to_create = "/mi";
                  var querystring = "?i="+group_id;
                   return (

                    <Link to={{pathname:link_to_create,search:querystring,state:{viewstatus: 'createlink'}} }>
                    <SoftButton title='Back to list' variant="gradient" size="small" color="info" >
                    
                    <FaBackward></FaBackward>
                    </SoftButton>
                    </Link>

                    
                    
                   )
                   }

                   
                     

                  })()
        
                }


              
             
              </SoftBox>

              </SoftBox>
              <SoftBox py={3}>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                

                <table class='table table-sm' width="100%">
                  <tr>
                    <td class='det0' width="25%">Series Name </td>
                    </tr><tr>
                    <td class='det1' width="70%">{this.state.series_name}</td>
                    </tr>
                    <tr>
                    <td class='det0'  >Date </td>
                    </tr><tr>
                    <td  class='det1'>{this.state.match_date}, {this.state.match_date_name}</td>
                    </tr>
                    

                </table>
                </SoftBox>
              </SoftBox>
              

      </Card>
      
        <SoftBox mb={3}>
        {
                (() => {
                  if(this.state.viewstatus === 'list' ){ 
                   return (
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Participants List</SoftTypography>
              
             
              



            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={member_columns} rows={this.state.normalmembers} />

              


            </SoftBox>


            

          </Card>
        )}
        else if(this.state.viewstatus === 'createlink' )
        {
        }
        else if(this.state.viewstatus === 'create' )
        { 
         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Join 
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" id="groupform">
            <SoftBox mb={2}>
              <input type='hidden' id='j_member_id'/>
              <SoftInput placeholder="Mobile Number" onKeyUp={this.onKeyDownSearch} name="j_mobile_no" id="j_mobile_no" inputProps={{ maxLength: 10}}  />
            </SoftBox>

            <SoftBox mb={2} id="j_members_list">
              
              
            </SoftBox>

            {
                (() => {

                  if(this.state.group_admin_flag === '1' || this.state.show_join_link_group_members_only ==='0' ){ 
  
                     return (
            <SoftBox mb={2}>
              <SoftInput placeholder="Name" name="j_full_name" id="j_full_name" inputProps={{ maxLength: 60}}  />
            </SoftBox>
            
            )
}
})()

}
{
                (() => {

                  if(this.state.group_admin_flag === '1'  || this.state.show_join_link_group_members_only ==='0'){ 
  
                     return (
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="dark" id='j_member_add_btn' fullWidth onClick={this.onClickAddLink}>
                Add
              </SoftButton>
        {this.state.loader_join ? <Spinner /> : null}

            </SoftBox>
                )
              }
              })()
              
              }
              
              <SoftBox display="flex" alignItems="center">
              <Checkbox value="1" id="j_keep"   />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                &nbsp;&nbsp;Need to add multiple? Tick this to keep the form.
              </SoftTypography>
              
            </SoftBox>
            
          </SoftBox>

          <Link to="javascript:void(0)">
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={this.backButtonClick}
                  >
                    Back to list
                  </SoftTypography>
                
              </Link>
              

        </SoftBox>

          </Card>
        
        )
        }

        else if(this.state.viewstatus === 'edit' )
        { 
         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Update Group 
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" id="groupform">
            <SoftBox mb={2}>
              Group Name
              <SoftInput placeholder="Name" name="group_name" id="group_name" inputProps={{ maxLength: 60}}  />
            </SoftBox>
            <SoftBox mb={2}>
              Location
              <SoftInput type="text" name="location" id="location" placeholder="Location" inputProps={{ maxLength: 100}} />
            </SoftBox>
            <SoftBox mb={2}>Upload Logo
              <SoftInput type="file" name="group_logo" id="group_logo" placeholder="Upload Logo"  />
            </SoftBox>

            <SoftBox mb={2}>Fetch Telegram Main Group Name
              <SoftInput type="text" name="main_telegram_chat_id" id="main_telegram_chat_id" placeholder="Fetch Telegram Main Group Name"  inputProps={{ maxLength: 30,readOnly:true}} />
            </SoftBox>
            <SoftBox mb={2}>Fetch Telegram Guest Group Name
              <SoftInput type="text" name="guest_telegram_chat_id" id="guest_telegram_chat_id" placeholder="Fetch Telegram Guest Group Name"  inputProps={{ maxLength: 30,readOnly:true}} />
            </SoftBox>
            
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="dark" fullWidth onClick={() => this.setState({viewstatus: 'list'})}>
                Update
              </SoftButton>
            </SoftBox>
            
          </SoftBox>

          <Link to="/groups">
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    Back to list
                  </SoftTypography>
                
              </Link>

        </SoftBox>

          </Card>
        
        )
        }

        else if(this.state.viewstatus === 'invite_link' )
        { 
         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Generate Invite Links
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>

        <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            > Generated Links
              <Table columns={links_columns} rows={linkrows} />
            </SoftBox>

            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            > 
            <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    Generate a new invite link
                  </SoftTypography>

            </SoftBox>

          <SoftBox component="form" role="form" id="groupform">
            <SoftBox mb={2}>
              <SoftInput placeholder="Max Limit" name="max_join_limit_with_link" id="max_join_limit_with_link" inputProps={{ maxLength: 4}}  />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="text" name="link_expiry" id="link_expiry" placeholder="Link Expiry Date" inputProps={{ maxLength: 10}} />
            </SoftBox>
            
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="dark" fullWidth onClick={this.onClickGenerateLink}>
                Generate
              </SoftButton>
            </SoftBox>
            
          </SoftBox>

          <Link to="/viewmembers?id=21">
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    Back to members list
                  </SoftTypography>
                
              </Link>
              

        </SoftBox>

          </Card>
        
        )
        }

        
      })()
        
        }
        </SoftBox>

      
      </SoftBox>
      
      <Modal
        isOpen={this.state.show}
        onRequestClose={this.closeModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <h2 >Profile</h2>
        
        <button variant="button" fontWeight="medium" color="info" onClick={this.closeModal}>close</button>
        <div id='i_profile_modal'>Player profile </div>
        
      </Modal>

      <Footer />
      
    </DashboardLayout>
    )  
  }  
}  

