import axios from 'axios';  
import team2x from "assets/images/team-2.jpg";
const someCommonValues = ['common', 'values'];

export const doSomethingWithInput = (theInput) => {
   //Do something with the input
   return theInput;
};
    
export const getPlayerProfile = (group_fk,user_fk,divname,invite_code='') => {
   
   //alert(group_fk + ' ' +user_fk)
   const post_data = { group_id: group_fk,member_id :  user_fk,invite_code:invite_code};
   var html_display = "<table width='100%' cellpadding=4 cellspacing=3>";
//      alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
  axios.post( global.config.app.backend_api_url + `groups/getplayerprofile`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      console.log(res.data);

      var iloop ;
      var player_details = res.data.player_details;
      for(iloop=0; iloop < player_details.length; iloop++)
      {
         var img_url = player_details[iloop].profile_pic ? (player_details[iloop].profile_pic):(team2x);
         
         if(player_details[iloop].profile_pic   !=='')
         {
         html_display = html_display + '<tr><td></td><td></td>';
         html_display = html_display + '<td><img width=150px height=150px src='+img_url+'></td>';
         html_display = html_display + '</tr>';
         }
         html_display = html_display + '<tr><td><b>Name</td><td>:</td>';
         html_display = html_display + '<td>'+player_details[iloop].member_name+'</td></tr>';
         
         
         html_display = html_display + '<tr><td><b>Mobile No</td><td>:</td>';
         html_display = html_display + '<td>'+player_details[iloop].mobile_no+'</td></tr><tr>';
         var img_url = player_details[iloop].cric_heroes_profile_link ;

         html_display = html_display + '<td>Cric Heroes Link</td><td>:</td>';
         if(img_url!=='')
         {
            html_display = html_display + '<td><a target=_blank href='+img_url+'>Link</a></td>';

         }
         else
         {
            html_display = html_display + '<td></td>';

         }
         html_display = html_display + '</tr>';

         if(player_details[iloop].overall_user_rating !=='' && player_details[iloop].overall_user_rating!==null && player_details[iloop].overall_user_rating !== undefined   )
         {
            html_display = html_display + '<tr><td><b>Rating</td><td>:</td>';
            html_display = html_display + '<td>'+player_details[iloop].overall_user_rating+'</td></tr>';
         }
         if(player_details[iloop].overall_comments!== undefined)
         {
            html_display = html_display + '<tr><td>Group Comments</td><td>:</td>';
            html_display = html_display + '<td>'+player_details[iloop].overall_comments+'</td>';
            html_display = html_display + '</tr>';
         }

         

         html_display = html_display + '<tr><td><b>Comments</td><td>:</td>';
            html_display = html_display + '<td>'+player_details[iloop].public_description_about_member+'</td></tr><tr>';
            html_display = html_display + '<td>Added On</td><td>:</td>';
            html_display = html_display + '<td>'+player_details[iloop].added_on+'</td>';
            html_display = html_display + '</tr>';
            var verified_status  =  player_details[iloop].verified_status;
            if(verified_status===1)
            {
               verified_status   =  'Verified';
            }else{
               verified_status   =  'Not Registered';
            }
            html_display = html_display + '<tr><td><b>Verified Status</td><td>:</td>';
            html_display = html_display + '<td>'+verified_status+'</td></tr><tr>';
            html_display = html_display + '<td>Type</td><td>:</td>';
            html_display = html_display + '<td>'+player_details[iloop].type+'</td>';
            html_display = html_display + '</tr>';
         
      }
      html_display = html_display + '</table>';
      document.getElementById(divname).innerHTML =  html_display;
   });
};