/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import axios from 'axios';  
import React from 'react';  
import PlayerCollage from './PlayerCollage';

// @mui material components
import Card from "@mui/material/Card";

import Parser from 'html-react-parser';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import logo1 from "assets/images/logo_livecricketlogo.png";

//import Modal from 'react-bootstrap/Modal';

// Data
//import membersTableData from "layouts/groups/data/membersTableData";

import SoftAvatar from "components/SoftAvatar";

// Images
import team2 from "assets/images/team-2.jpg";



export default class Miplain extends React.Component {  
  constructor(props) {
    super(props);
    this.setState({loggedIn: '1'});
    this.setState({showModal: false});
    this.setState({viewstatus: 'list'});

    this.state = {
        series_name: 'Champions League',
        team_name: 'Dream Team',
        players: [
          { full_name: 'Player 1', profile_pic: 'https://via.placeholder.com/100' },
          { full_name: 'Player 2', profile_pic: 'https://via.placeholder.com/100' },
          { full_name: 'Player 3', profile_pic: 'https://via.placeholder.com/100' },
          { full_name: 'Player 4', profile_pic: 'https://via.placeholder.com/100' },
          { full_name: 'Player 5', profile_pic: 'https://via.placeholder.com/100' },
          { full_name: 'Player 6', profile_pic: 'https://via.placeholder.com/100' },
          { full_name: 'Player 7', profile_pic: 'https://via.placeholder.com/100' },
          { full_name: 'Player 8', profile_pic: 'https://via.placeholder.com/100' },
          { full_name: 'Player 9', profile_pic: 'https://via.placeholder.com/100' },
          { full_name: 'Player 10', profile_pic: 'https://via.placeholder.com/100' },
          // Add more players as needed
        ]
      }
  }
 

  componentDidMount() { 

    
    this.getbasicajax();

      

  }  

  getbasicajax()
  {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let invite_code = params.get('t');
    //alert(invite_code)
    const post_data = { team_hash_code: invite_code };
    
//      alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
  axios.post( global.config.app.backend_api_url + `auction/get_team_info`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      //console.log("after112")
     // console.log(res.data)
      
      this.setState( {series_name: res.data.team[0].series_name
        ,team_name: res.data.team[0].team_name
        ,team_logo : res.data.team[0].team_logo
        ,league_logo : res.data.team[0].league_logo
        ,auction_link  : res.data.team[0].auction_link
        ,players  : res.data.team[0].players
        }
        );

       
    });
  }
  
  
  
  
  

  render() {  

    return (  
      <SoftBox>
      
      <PlayerCollage
        seriesName={this.state.series_name}
        teamName={this.state.team_name}
        teamLogo={this.state.team_logo}
        leagueLogo = {this.state.league_logo}
        ourLogo = {logo1}
        players={this.state.players}
        auctionLink={this.state.auction_link}
      />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
  {this.state.league_logo ? (
    <img
      src={this.state.league_logo}
      alt="League Logo"
      style={{ maxHeight: '200px', marginRight: '20px' }}
    />
  ) : null}

  {/*<img src={logo1} width="200px" alt="Logo 1" />*/}
  </div>

    </SoftBox>
    )  
  }  
}  
