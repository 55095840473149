/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import axios from 'axios';  
import React from 'react';  
import {  Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import SoftInput from "components/SoftInput";

import gr_logo from "assets/images/group.png";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Helmet } from "react-helmet";
import FormSelect from 'react-bootstrap/FormSelect';

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbarNotLooged from "examples/Navbars/DashboardNavbarNotLooged";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { useState, useRef} from 'react';
import Button from 'react-bootstrap/Button';
import {getPlayerProfile} from 'Utils.js'
import { FaImages } from "react-icons/fa";
import { FaBasketballBall } from "react-icons/fa";

import Modal from 'react-modal';
import { FaMinusCircle } from "react-icons/fa";
import { FaPenSquare } from "react-icons/fa";
import { FaShare } from "react-icons/fa";
import { FaPaypal } from "react-icons/fa";


//import Modal from 'react-bootstrap/Modal';

// Data
//import membersTableData from "layouts/groups/data/membersTableData";

import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { Navigate } from "react-router-dom";
import SoftButton from "components/SoftButton";
import Checkbox from "@mui/material/Checkbox";

import whatsapp_logo from "assets/images/logos/whatsapp.png";
import auction_hammer from "assets/images/auction_hammer.png";


import telegram_logo from "assets/images/logos/telegram.png";
import loader_img from "assets/images/loader.svg";

import 'App.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {zIndex: 100000}   
};

const Spinner = () => (
  <img src={loader_img} class="zoom2" height="30"/>
)
function createsearchlist({ list }) {
  alert('here.')

}
//(iloop+1) + '. '+ full_name +' ( '  + mobile_no + " ) <a href='javascript:void(0)' onClick=window.clickReslink()>Add</a><br>"

function Author({ image, name, email }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column" >
        <SoftTypography variant="button" fontWeight="medium" color="info">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};
function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
};


export default class Mi extends React.Component {  
  constructor(props) {


    super(props);
    this.setState({loggedIn: '1'});
    this.setState({showModal: false});
    this.setState({viewstatus: 'list'});
    this.ref_j_mobile_no = React.createRef();
    window.helloComponent = this;
    this.setCookieIfNotSet('cooUserID', 365);




    this.state = { loggedIn: '1',showModal: false,viewstatus:'list',linkrows:[],groupname:'Friends Group',group_logo:team2,current_player_profile:'<h4>Player details</h4>'};
//    this.setState({linkData:{rows:[]}});
    
  }
  state = {  
    normalmembers: []  ,
    guestmembers:[]

  }  

  handleChange = (event) => {
    this.setState({ c_icon_tex: event.target.value });
  };


   setCookieIfNotSet(cookieName, daysToExpire) {
    var existingCookie = this.getCookie(cookieName);
    
    if (!existingCookie) {
        var randomValue = this.generateRandomUniqueValue();
        
        // Set the expiration date to a future date
        var expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + daysToExpire);
        
        // Format the expiration date in the cookie format
        var expires = "expires=" + expirationDate.toUTCString();
        
        // Set the cookie with the generated value and the long expiry
        document.cookie = `${cookieName}=${randomValue}; ${expires}; path=/`;
    }
}
 getCookie(cookieName) {
  var name = cookieName + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var cookieArray = decodedCookie.split(';');
  for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i];
      while (cookie.charAt(0) == ' ') {
          cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) == 0) {
          return cookie.substring(name.length, cookie.length);
      }
  }
  return null;
}

generateRandomUniqueValue() {
    // Generate a random value using timestamp and Math.random()
    return Date.now().toString(36) + Math.random().toString(36).substr(2, 5);
}

  clickedAddMember(val_id,uname){
   // alert("Called from outside"+val_id);
   localStorage.setItem("storedIUserName", uname);
   localStorage.setItem("storedIUserCode", val_id);


    document.getElementById('j_member_id').value    = val_id;
    this.onClickAddLink();
 }

 clickedAddMemberC()
 {
  // alert('dfd')
  document.getElementById('j_member_id').value    = localStorage.getItem('storedIUserCode');
  //alert(document.getElementById('j_member_id').value )
  window.helloComponent.onClickAddLink();
 }

 clickedAddMemberQuick()
 {
  // alert('dfd')
  //document.getElementById('j_member_id').value    = localStorage.getItem('storedIUserCode');
  //alert(document.getElementById('j_member_id').value )
  window.helloComponent.onClickAddLinkQuick();
 }
 


 closeshowVerify= (e)=> {
  this.setState({
    showVerify: false
  });

}

closeshowChangeType= (e)=> {
  this.setState({
    showChangeType  : false
  });

}
 onClickGenOTPR= (e)=> {

  


  let search = window.location.search;
    let params = new URLSearchParams(search);
    let invite_code = params.get('i');

    console.log( this.state.normalmembers);
    //const normalmembers = this.state.normalmembers.filter(item => item.id !== id);  
    //this.setState({ normalmembers }); 
    var cooUserID = this.getCookie('cooUserID');
    const post_data = { invite_code: invite_code,series_participant_id:this.state.series_participant_id,cooUserID:cooUserID };


  //const post_data = { mobile_no: mobile_no };
  
  axios.post(global.config.app.backend_api_url +`matchschedule/generate_remove_otp`, post_data, { withCredentials: true,
    headers: {
      'content-type': 'application/json', 
   } })  
    .then(res => {  
      console.log(res);
      alert(res.data.message);
      if(res.data.status=='1')
      {
        document.getElementById('i_remove_modal_click').style.display='none';
        document.getElementById('i_remove_modal_form').style.display='';

      }
    }).catch((error) => {
      // Error
      console.log('response: ', error.response.data);
      
      //alert("Errror")
      if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
      } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the 
          // browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
      } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
      }
      console.log(error.config);
  });

 }

 onClickSwapPlayerBeforeAuction = (e) => {

  var mi_e_new_user_mobile_number = document.getElementById('mi_e_new_user_mobile_number').value

  document.getElementById("michangeswapplayer").style.display="none";
  //document.getElementById("loadergif_change").style.display="";
  


  let search = window.location.search;
  let params = new URLSearchParams(search);
  //let series_id = atob(params.get('i'));
  //alert(this.state.e_selected_sl)
  const post_data = { series_id: this.state.series_id, new_user_mobile_number: mi_e_new_user_mobile_number,series_participant_id:this.state.series_participant_id};
  console.log(post_data)
  //      alert(group_id)
  //,{headers: {"Content-Type": "application/json"}}
  axios.post(global.config.app.backend_api_url + `auction/swap_player_before_auction_sell`, post_data, { withCredentials: true }
  )
    .then(res => {
      //document.getElementById("loadergif_change").style.display="none";
      document.getElementById("michangeswapplayer").style.display="";

      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if (res.data.status == '0') {
        alert(res.data.message);
      }
      else {
        alert(res.data.message);
        if(res.data.status=='1')
        {
          window.location.reload();
        }
      }

    });

  // alert('API to be called');
}
 onClickMChangeType= (e)=> {
  this.setState({error_msg: ''});
  const mi_member_type = document.getElementById('mi_member_type').value
  const mi_is_icon = document.getElementById('mi_is_icon').value
  const mi_icon_text = document.getElementById('mi_icon_text').value
  

  //const post_data = { mobile_no: mobile_no };

  let search = window.location.search;
    let params = new URLSearchParams(search);
    let invite_code = params.get('i');

    console.log( this.state.normalmembers);
    //const normalmembers = this.state.normalmembers.filter(item => item.id !== id);  
    //this.setState({ normalmembers }); 
    var cooUserID = this.getCookie('cooUserID');
    const post_data = {mi_member_type:mi_member_type,
      mi_is_icon:mi_is_icon,mi_icon_text:mi_icon_text,
      invite_code: invite_code,series_participant_id:this.state.series_participant_id };
    

  
  axios.post(global.config.app.backend_api_url +`matchschedule/change_participant_type`, post_data, { withCredentials: true,
    headers: {
      'content-type': 'application/json', 
   } })  
    .then(res => {  
      console.log(res);
      alert(res.data.message);
      if(res.data.status=='1')
      {
        window.location.reload();
      }
    }).catch((error) => {
      // Error
      console.log('response: ', error.response.data);
      //this.ref_error_msg.current  = error.response.data.message;
      //this.setState({error_msg: error.response.data.message});
      //alert("Errror")
      if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
      } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the 
          // browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
      } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
      }
      console.log(error.config);
  });

    
  }

 onRClickVerify= (e)=> {
  this.setState({error_msg: ''});
  const otp = document.getElementById('rem_otp').value

  //const post_data = { mobile_no: mobile_no };

  let search = window.location.search;
    let params = new URLSearchParams(search);
    let invite_code = params.get('i');

    console.log( this.state.normalmembers);
    //const normalmembers = this.state.normalmembers.filter(item => item.id !== id);  
    //this.setState({ normalmembers }); 
    var cooUserID = this.getCookie('cooUserID');
    const post_data = {remove_otp_flow:1, invite_code: invite_code,series_participant_id:this.state.series_participant_id,cooUserID:cooUserID,remove_otp:otp };
    

  
  axios.post(global.config.app.backend_api_url +`matchschedule/remove_participant`, post_data, { withCredentials: true,
    headers: {
      'content-type': 'application/json', 
   } })  
    .then(res => {  
      console.log(res);
      alert(res.data.message);
      if(res.data.status=='1')
      {
        window.location.reload();
      }
    }).catch((error) => {
      // Error
      console.log('response: ', error.response.data);
      this.ref_error_msg.current  = error.response.data.message;
      this.setState({error_msg: error.response.data.message});
      //alert("Errror")
      if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
      } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the 
          // browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
      } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
      }
      console.log(error.config);
  });

    
  }

    
 showModal (group_fk,user_fk) {

  let search = window.location.search;
    let params = new URLSearchParams(search);
    let invite_code = params.get('i');

  getPlayerProfile(group_fk,user_fk,'i_profile_modal',invite_code);
  //call API i_profile_modal
  //alert(group_fk + ' ' + user_fk)
  this.setState({
    show: !this.state.show
  });
};

  closeModal = e => {
    this.setState({
      show: false
    });
  
};

  componentDidMount() { 
    
      /*
    axios.get(global.config.app.backend_api_url +`groups/setsession?user_id=2`, { withCredentials: true })//, { withCredentials: true }  
      .then(res => {  

      });*/


      /*
      axios.get(global.config.app.backend_api_url +`testAPIp`, { withCredentials: true })  
      .then(res => {  
        this.setState({loggedIn: res.data.loggedIn, designation: "your value"});

      });*/
      this.getbasicajax();

      

  }  

  getbasicajax()
  {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let invite_code = params.get('i');
//    alert(invite_code)

  var cooUserID = this.getCookie('cooUserID');

    const post_data = { invite_code: invite_code ,cooUserID:cooUserID };
    
//      alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
  axios.post( global.config.app.backend_api_url + `matchschedule/getbyinvite_code`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)

      var storedIUserName = localStorage.getItem("storedIUserName");
      var storedIUserCode = localStorage.getItem("storedIUserCode");
      
      //alert(res.data.link_data[0].total_guests_participants + ' ' + res.data.link_data[0].total_participants)
      this.setState( {user_id: res.data.user_id,groupname:res.data.link_data[0].group_name,group_admin_flag : res.data.link_data[0].is_admin
        ,series_id : res.data.link_data[0].series_id,series_name : res.data.link_data[0].series_name
        ,match_date : res.data.link_data[0].match_date
        ,match_date_name:res.data.link_data[0].match_date_name
        ,show_join_link_group_members_only:res.data.link_data[0].show_join_link_group_members_only
        ,from_time : res.data.link_data[0].from_time
        ,to_time : res.data.link_data[0].to_time
        ,header_description : res.data.link_data[0].header_description
        ,footer_description : res.data.link_data[0].footer_description
        ,total_participants : res.data.link_data[0].total_participants
        ,total_waiting_list : res.data.link_data[0].total_waiting_list
        ,total_guests_participants: res.data.link_data[0].total_guests_participants
        ,add_delete_permission_cutoff_time : res.data.link_data[0].add_delete_permission_cutoff_time
        ,group_id : res.data.link_data[0].group_id
        ,joined_flag : res.data.link_data[0].joined_flag
        ,remaining_slots : res.data.link_data[0].remaining_slots
        ,show_link  : res.data.link_data[0].show_link
        ,status_name : res.data.link_data[0].status_name
        ,group_logo : res.data.link_data[0].group_logo
        ,storedIUserName:storedIUserName,
        storedIUserCode:storedIUserCode,
        photo_upload  : res.data.link_data[0].photo_upload,
        selected_player_photo:'',
        missing_sl  : res.data.missing_sl

      }
        );

        this.setState( {
          og_title : res.data.link_data[0].group_name + ' - '+res.data.link_data[0].series_name + ' - '+res.data.link_data[0].match_date,
          og_description : res.data.link_data[0].remaining_slots +' slots remaining at '+ res.data.link_data[0].group_name + ' - '+res.data.link_data[0].series_name + ' - '+res.data.link_data[0].match_date
        });

      const normalmembers = this.createMemberRows(res.data.players_list);
      console.log("getting response "+res.data.players_list)
      this.setState({ normalmembers });  
      
      const wl_members = this.createMemberRows(res.data.wl_players_list);
      console.log("getting wl_members response "+res.data.wl_players_list)
      this.setState({ wl_members });  

      if(res.data.user_details.user_id!='')
        {
          this.callNotificationSubscribe(res.data.user_details.nai,res.data.user_details.user_id);
        }

        var share_content = ''
      
      //alert(this.state.group_admin_flag)
      


      

    });
  }
 

  callNotificationSubscribe(appid,userid)
  {
    if(userid=='' || appid=='')
    {
      return;
    }
    return;
    const s = document.createElement("script")
    s.src = "https://notix.io/ent/current/enot.min.js"
    s.onload = (sdk) => {
        sdk.startInstall({
            appId: appid,
            loadSettings: true,
            disableConsoleDebug: true,
            user: userid
        });

    }
    document.head.append(s);
  }


  clickReslink = (e)=> {

    alert('here1')
  //document.getElementById('j_member_id').value;
//  document.getElementById('j_member_add_btn').click();
  }

 onClickGenerateLink = (e)=> {
  alert('API to be called');
    this.setState({viewstatus: 'invite_link'});
  }
  onKeyDownSearch = (e)=> {

    const j_mobile_no = document.getElementById('j_mobile_no').value

    document.getElementById('j_member_id').value    = '';
    this.ref_j_mobile_no.current  = j_mobile_no;

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let invite_code = params.get('i');
    

    const post_data = { mobile_no: j_mobile_no,invite_code : invite_code };
      
//      alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
    axios.post( global.config.app.backend_api_url + `members/search`,post_data, { withCredentials: true }
    )  
      .then(res => {  
        //const normalmembers = res.data;  
        console.log("after112")
        
        
        document.getElementById("j_members_list").innerHTML=  '';
        var html_content = '<table>';
        if(res.data.status!='0')
        {
          console.log(res.data)
          //const normalmembers = this.createMemberRows(res.data);
          var iloop ;
          for(iloop=0; iloop < res.data.length; iloop++){
              const full_name = res.data[iloop].full_name;
              const mobile_no = res.data[iloop].mobile_no;
              var m_user_id = res.data[iloop].user_id;
              //console.log(full_name)

              var profile_pic = res.data[iloop].profile_pic;

              //html_content = html_content + createsearchlist(full_name)
              if(profile_pic  ===  '' || profile_pic === null)
              {
                html_content = html_content + '<tr style="border-bottom: 1px solid gray;"><td style="border-bottom: 1px solid gray;">' + (iloop+1) + '.</td><td style="border-bottom: 1px solid gray;"></td><td style="border-bottom: 1px solid gray;">'+ full_name +' ( '  + mobile_no + " ) <a href='javascript:void(0)' onclick='window.helloComponent.clickedAddMember(&#39;"+m_user_id+"&#39;,&#39;"+full_name+"&#39;)'>Add</a></td></tr>";
              }
              else
              {
                html_content = html_content + '<tr style="border-bottom: 1px solid gray;"><td style="border-bottom: 1px solid gray;">' + (iloop+1) + '.</td><td style="border-bottom: 1px solid gray;"><img src="'+profile_pic+'" width="80px" height="80px"></td><td style="border-bottom: 1px solid gray;">'+ full_name +' ( '  + mobile_no + " ) <a href='javascript:void(0)' onclick='window.helloComponent.clickedAddMember(&#39;"+m_user_id+"&#39;,&#39;"+full_name+"&#39;)'>Add</a></td></tr>";
               // html_content = html_content +'<tr><td>' (iloop+1) + ".</td><td><img src='"+profile_pic+"' width='150px' /> </td> <td>"+ full_name +' ( '  + mobile_no + " ) <a href='javascript:void(0)' onclick='window.helloComponent.clickedAddMember(&#39;"+m_user_id+"&#39;,&#39;"+full_name+"&#39;)'>Add</a></td></tr>";
              }

          }
          html_content  = html_content  + '</table>';
          document.getElementById("j_members_list").innerHTML=html_content;
          const links_columns1 = [{ name: "full_name", align: "left" },
          { name: "mobile_no", align: "left" }];

         


        }else
        {
          document.getElementById("j_members_list").innerHTML=  'No members found.';

        }
        


        

      });

    

//    alert('API to be called..'+this.ref_j_mobile_no.current);
  }
  onClickShareWhatsapp= (e)=> {
    var share_content = ';';
    var url = "https://wa.me/?text="+share_content;
    window.open(url);
  }
  onClickNotify = (e)=> {

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let invite_code = params.get('i');


    const post_data = { invite_code : invite_code };
    console.log(post_data)
//      alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
  axios.post( global.config.app.backend_api_url + `matchschedule/sendnotification`,post_data, { withCredentials: true }
  )  
    .then(res => {  

      
      alert(res.data.message);

    });



  }
  backButtonClick = (e)=> {
    this.getbasicajax();
    this.setState({viewstatus: 'list'});
    
  }

  ondMIImageChange = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
     // alert(img)
  
      const formData = new FormData();
    // alert(document.getElementById('dp_member_id').value)
      formData.append("profile_image", event.target.files[0]);
      formData.append("midp_member_id", this.state.selected_member);
      var cooUserID = this.getCookie('cooUserID');
      formData.append("cooUserID", cooUserID);
      
      //call API
      console.log(formData)
  
      const pconfig = {     
        headers: { 'content-type': 'multipart/form-data' }
    }
  
      axios.post( global.config.app.backend_api_url + `members/uploadprofilepic_byadmin_fromlink`,formData,pconfig, { withCredentials: true }
      )  
        .then(res => {  
          //const normalmembers = res.data;  
          console.log("after112")
          console.log(res.data)
          if(res.data.status=='0')
          {
            alert(res.data.message);
          }
          else
          {
            alert(res.data.message);
            //alert(res.data.profile_pic)
            /*
            this.setState({
              image: URL.createObjectURL(img)
            });
            */
            this.setState({ viewstatus: 'list'});
  
          }
    
        });
  
        
  
     
    }
  };


  ondMIICricmageChange = event => {
     // alert(img)
  
      const formData = new FormData();
    // alert(document.getElementById('dp_member_id').value)
      formData.append("profile_link", document.getElementById('micricdp_profile_pic_link').value);
      formData.append("midp_member_id", this.state.selected_member);
      var cooUserID = this.getCookie('cooUserID');
      formData.append("cooUserID", cooUserID);
      
      //call API
      console.log(formData)
  
      const pconfig = {     
        headers: { 'content-type': 'multipart/form-data' }
    }
  
      axios.post( global.config.app.backend_api_url + `members/update_cric_profilelink_byadmin_fromlink`,formData, { withCredentials: true }
      )  
        .then(res => {  
          //const normalmembers = res.data;  
          console.log("after112")
          console.log(res.data)
          if(res.data.status=='0')
          {
            alert(res.data.message);
          }
          else
          {
            alert(res.data.message);
            //alert(res.data.profile_pic)
            /*
            this.setState({
              image: URL.createObjectURL(img)
            });
            */
            this.setState({ viewstatus: 'list'});
  
          }
    
        });
  
        
  
     
    
  };


   getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      //console.log(reader.result);
      return reader.result;
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
 }


 onClickAddLinkQuick = (e)=> {
  this.setState({loader_join: true});
  var j_mobile_no = '';//document.getElementById('j_mobile_no').value
  this.ref_j_mobile_no.current  = j_mobile_no;
  var j_member_id = '';


  var j_full_name = '';
  try{
    j_full_name = '';//document.getElementById('j_full_name').value
    
  }catch(Err)
  {
  }

  try{
    j_member_id = localStorage.getItem('storedIUserCode');  //document.getElementById('j_member_id').value        
  }catch(Err)
  {
  }

  var j_photo_upload  = '';
  try{

    var file = document.querySelector('#photo_upload').files[0];
    j_photo_upload = this.getBase64(file);
    
  }catch(Err)
  {
  }
  
//alert('df')


  let search = window.location.search;
  let params = new URLSearchParams(search);
  let invite_code = params.get('i');
  var cooUserID = this.getCookie('cooUserID');

  const post_data = { member_mobile_no: j_mobile_no,invite_code : invite_code,member_name:j_full_name,member_id:j_member_id,cooUserID:cooUserID ,photo_upload:j_photo_upload};
    console.log(post_data)
//      alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
  axios.post( global.config.app.backend_api_url + `matchschedule/add_participant`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        //document.getElementById('j_mobile_no').value = '';
        try{
          //document.getElementById('j_full_name').value  =''
          
        }catch(Err)
        {
        }
  
        try{
          //document.getElementById('j_member_id').value     =''   
        }catch(Err)
        {
        }

        
        
      }
      this.setState({loader_join: false});
      window.location.reload();


    });



// alert('API to be called');
}

    onClickAddLink = (e)=> {
      this.setState({loader_join: true});
      var j_mobile_no = document.getElementById('j_mobile_no').value
      this.ref_j_mobile_no.current  = j_mobile_no;
      var j_member_id = '';

      var j_keep_checked = document.getElementById('j_keep').checked;

      var j_full_name = '';
      try{
        j_full_name = document.getElementById('j_full_name').value
        
      }catch(Err)
      {
      }

      try{
        j_member_id = document.getElementById('j_member_id').value        
      }catch(Err)
      {
      }

      var j_photo_upload  = '';
      try{

        var file = document.querySelector('#photo_upload').files[0];
        j_photo_upload = this.getBase64(file);
        
      }catch(Err)
      {
      }
      
//alert('df')

  
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let invite_code = params.get('i');
      var cooUserID = this.getCookie('cooUserID');
  
      const post_data = { member_mobile_no: j_mobile_no,invite_code : invite_code,member_name:j_full_name,member_id:j_member_id,cooUserID:cooUserID ,photo_upload:j_photo_upload};
        console.log(post_data)
  //      alert(group_id)
  //,{headers: {"Content-Type": "application/json"}}
      axios.post( global.config.app.backend_api_url + `matchschedule/add_participant`,post_data, { withCredentials: true }
      )  
        .then(res => {  
          //const normalmembers = res.data;  
          console.log("after112")
          console.log(res.data)
          if(res.data.status=='0')
          {
            alert(res.data.message);
          }
          else
          {
            alert(res.data.message);
            document.getElementById('j_mobile_no').value = '';
            try{
              document.getElementById('j_full_name').value  =''
              
            }catch(Err)
            {
            }
      
            try{
              document.getElementById('j_member_id').value     =''   
            }catch(Err)
            {
            }

            
            if(!j_keep_checked)
            {
            this.getbasicajax();
            this.setState({viewstatus: 'list'});
            }
          }
          this.setState({loader_join: false});

        });
  
    

   // alert('API to be called');
  }

  deleteRow(id,name, e){  

    if(window.confirm('Remove '+name+' ?')===false)
    {
      return false;
    }
    
    //alert("deleted id : "+ id);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let invite_code = params.get('i');

    //console.log( this.state.normalmembers);
    //const normalmembers = this.state.normalmembers.filter(item => item.id !== id);  
    //this.setState({ normalmembers }); 
    var cooUserID = this.getCookie('cooUserID');
    const post_data = { invite_code: invite_code,series_participant_id:id,cooUserID:cooUserID };
     
    axios.post( global.config.app.backend_api_url + `matchschedule/remove_participant`,post_data, { withCredentials: true }
    )  
      .then(res => {  
        //const normalmembers = res.data; 
        if(res.data.status=='0')
        {
          alert(res.data.message );
          if(res.data.pop_flag=='1')
          {
            this.setState({pop_message:res.data.pop_msg,series_participant_id:id,showVerify: true});
          }
          
        } 
        else{
          alert(res.data.message);
        window.location.reload();
        }
  

        

      });


    
  }  

  membersTableColumns = {
    member_columns : [
      { name: "slno",align: "left" },
      { name: "member_name",align: "left" },
      { name: "mobile_no",align: "left" },
      { name: "added_on",align: "left" },
      { name: "action",align: "left" },
    ]
  }
  
  linkTableColumns = {
    links_columns: [
      { name: "invitelink", align: "left" },
      { name: "max_join_limit_with_link", align: "left" },
      { name: "current_join_count", align: "left" },
      { name: "link_expiry", align: "left" },
      { name: "action", align: "left" },
    ]
  };

  linkTableRows = {
    linkrows: [
      {
        invitelink: <Author image={team2} name="https://cricketgroups.in/sdfdsf"  />,
        max_join_limit_with_link: <Function job="10"  />,
        current_join_count: <Function job="1"  />,
        link_expiry: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            23/12/2023
          </SoftTypography>
        ),
        action: (
          <SoftBox>


                  

</SoftBox>

        ),
      }
      
    ],
  };


   membersTableData = {
    columns: [
      { name: "slno", align: "left" },
      { name: "partcipant_name", align: "left" },
      { name: "action", align: "left" },
    ],
  
    rows: [
      {
        slno : <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        1
      </SoftTypography>,
        member_name: <Author image={team2} name="Friends Group"  />,
        location: <Function job="Trivandrum"  />,
        memberscount: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            25
          </SoftTypography>
        ),
        added_on: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            12/11/2022
          </SoftTypography>
        ),
        action: (
          <SoftBox>
          <SoftTypography
            component="a"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            <FaMinusCircle></FaMinusCircle>
          </SoftTypography>
</SoftBox>

        ),
      }
      
    ],
  };

  createMemberRows(res)
{
  //loop here and create rows in soft UI rows
  console.log(res)
  const plainObj = [];

  var iloop ;
  for(iloop=0; iloop < res.length; iloop++){
      const thisid = res[iloop].id;
      const g_user_fk = res[iloop].series_participant_reg_user_fk;
      const g_group_fk = res[iloop].group_fk;
      const series_participant_id= res[iloop].series_participant_id;
      const href_link = "/viewmembers/"+thisid;
      const participant_member_type= res[iloop].participant_member_type;
      const s_sl_no = res[iloop].sl_no;
      const ppic = res[iloop].profile_pic;
      const allow_pic_upload_by_gr_admin  = res[iloop].allow_pic_upload_by_gr_admin;
      
      const is_icon= res[iloop].is_icon;
      const icon_text= res[iloop].icon_text;

      var icon_new_flag   = '';
      var ff= 0 ;

      if(is_icon  ==  '1' || icon_text!='')
      {
        icon_new_flag = ' (';
        ff  = 1;
      }

      if(is_icon  ==  '1')
      {
        icon_new_flag = icon_new_flag+' ICON/OWNER';
        
      }

      if(icon_text  !=  '')
      {
        icon_new_flag = icon_new_flag +':'+icon_text ;
        
      }

      if(ff==1)
      {
        icon_new_flag = icon_new_flag+' )';
      }
      
      const total_participants= res[iloop].total_participants;
      const total_guests_participants= res[iloop].total_guests_participants;
      const const_member_name = res[iloop].player_name;
      const allow_cricheroes_pic_upload_by_gr_admin = res[iloop].allow_cricheroes_pic_upload_by_gr_admin;
      const cric_heroes_profile_img = res[iloop].cric_heroes_profile_img;
      const cric_heroes_profile_link= res[iloop].cric_heroes_profile_link;
      //alert(total_participants + ' , ' + total_guests_participants)
      var guest_flag = '';
      if(total_participants !== total_guests_participants && participant_member_type ==  '2')
      {
        guest_flag  = '(G)';
        //alert(guest_flag)
      }
      //loopData += `<li>${data[i].name}</li>`
      const arrayLike = {
        slno: <Function job={s_sl_no}  />,
        member_name: (
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={res[iloop].profile_pic ? (res[iloop].profile_pic):(team2)} alt={res[iloop].player_name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column" >
        <SoftTypography component="a" href="javascript:void(0)"  onClick={() => this.showModal(g_group_fk,g_user_fk)} variant="button" fontWeight="medium" color="info">
        {res[iloop].player_name} {guest_flag} {icon_new_flag}
        </SoftTypography>
        
      </SoftBox>
    </SoftBox>
),
        mobile_no: <Function job={res[iloop].mobile_no}  />,
        added_on: <Function job={res[iloop].added_on}  />,
        memberscount: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            25
          </SoftTypography>
        ),
        'added_on': res[iloop].added_on,
        'action' : (

          <SoftBox>

{
                (() =>{
            if(res[iloop].del_flag == '1' )//this.state.group_admin_flag
            {
              var s_cap = 'Change type for '+res[iloop].player_name;
              return(
            <SoftTypography
            component="a"
            href="javascript:void(0)"
            variant="caption"
            color="secondary"
            title="Edit"
            fontWeight="medium"
            onClick={() => this.setState({showChangeType:true,c_is_icon:is_icon,c_icon_tex:icon_text, c_participant_member_type:participant_member_type,change_pop_message:s_cap,series_participant_id:series_participant_id})}
          >
        <FaPenSquare></FaPenSquare>

            
          </SoftTypography>
              )
              
            }
            })()
        
                
          }&nbsp;&nbsp;

          {
(() =>{
  //alert(res[iloop].del_flag + "," +allow_pic_upload_by_gr_admin+","+ppic )
            if((res[iloop].del_flag == '1' && allow_pic_upload_by_gr_admin=='1' && ppic== '') || res[iloop].always_dp=='1')//this.state.group_admin_flag
            {
              return(
            <SoftTypography
            component="a"
            href="javascript:void(0)"
            variant="caption"
            color="secondary"
            title="Upload Pic"
            fontWeight="medium"
            onClick={() => this.setState({viewstatus: 'uploaduserdp',selected_member:g_user_fk,selected_member_name:const_member_name})}
          >
        <FaImages></FaImages>

            
          </SoftTypography>
              )
              
            }
            })()
        
                
          }
          &nbsp;
          &nbsp;

          {
(() =>{
//  alert(this.state.group_admin_flag  + ","+allow_cricheroes_pic_upload_by_gr_admin+","+cric_heroes_profile_img)
  //alert(res[iloop].del_flag + "," +allow_pic_upload_by_gr_admin+","+ppic )
  //alert(cric_heroes_profile_link)
            if(cric_heroes_profile_link == '')//this.state.group_admin_flag
            {
              return(
            <SoftTypography
            component="a"
            href="javascript:void(0)"
            variant="caption"
            color="secondary"
            title="Update CricHeroes Profile link"
            fontWeight="medium"
            onClick={() => this.setState({viewstatus: 'uploadusercricdp',selected_member:g_user_fk,selected_member_name:const_member_name})}
          >
        <FaBasketballBall></FaBasketballBall>

        &nbsp;
          &nbsp;
         
          </SoftTypography>
              )
              
            }
            })()
        
                
          }
           
          

          
          {
                (() => {

                  //if(this.state.group_admin_flag === 1 || this.state.user_id ===  '1' || 1==1){ //check cookie user id for temporary
                  if(res[iloop].del_flag == '1')  // && this.state.show_link == '1'
                  {
                    var player_name = res[iloop].player_name;
                     return (

              
          <SoftTypography
          component="a"
          href="javascript:void(0)"
          variant="caption"
          color="secondary"
          title="Delete"
          fontWeight="medium"
          onClick={() => this.deleteRow(series_participant_id , player_name)}
        >
         <FaMinusCircle></FaMinusCircle>
        </SoftTypography>
                     )
                     }
                    })()
          
                  }


          

          </SoftBox>
          
        ),
        'id' : res[iloop].id
      };
      Array.prototype.push.call(plainObj, arrayLike);

  }
  
    //const myObjStr = JSON.stringify(plainObj);
    console.log("obj:")
    console.log(plainObj)
    console.log("res:")
    console.log(res)
    
    return plainObj;
  }

  
  

  render() {  

    const handleClose = () => {
      this.setState({showModal: false});
    }
    const handleShow = () => {
      this.setState({showModal: true});
    }


  const { columns, rows } = this.membersTableData;

  const {links_columns} = this.linkTableColumns;

  const {linkrows} = this.linkTableRows;
  const {member_columns} = this.membersTableColumns;

  var sel_1 = '';
  var sel_2 = '';
    

  //const [list, setList] = useState([]);
  //const [actuallist, setActuallist] = useState([]);

    console.log("sess_data:");  
    console.log(this.state.loggedIn);  

    return (  
      <DashboardLayout>
      <Helmet>
      <title>Match Invite | {global.config.app.name}</title>
      <meta
        name="description"
    content="Join Our Cricket Team - Experience the thrill of competitive cricket and be part of a supportive community. Sign up now and showcase your skills on the field."
      />
      <meta
        name="keywords"
        content="Cricket, team, join, competitive, skills, community, sign up, field"
      />
      <meta property="og:title" content={this.state.og_title} />
      <meta property="og:description" content={this.state.og_description}/>
    </Helmet>
      <DashboardNavbarNotLooged />
      <SoftBox py={3}>
      <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftAvatar
              src={this.state.group_logo ? (this.state.group_logo):(gr_logo)}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
            />
            {this.state.groupname} 
              

              <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              

          {
            /*
                (() => {
                  let search = window.location.search;
                    let params = new URLSearchParams(search);
                    let group_id = params.get('i');

                  if(this.state.group_admin_flag === '1' ){ 

                  var link_to_create = "/miupdatepay";
                  var querystring = "?i="+group_id;
                   return (

                    <Link to={{pathname:link_to_create,search:querystring,state:{viewstatus: 'createlink'}} }>
                    <SoftButton title='Update payment' variant="gradient" size="small" color="info" >
                    
                    <FaPaypal></FaPaypal>
                    </SoftButton>
                    </Link>

                    
                    
                   )
                   }

                   
                     

                  })()
        */
                }


              {
                (() => {
                  let search = window.location.search;
                    let params = new URLSearchParams(search);
                    let group_id = atob(params.get('id'));

                  if(this.state.group_admin_flag === '1' ){ 

                  var link_to_create = "javascript:void(0)";
                  var querystring = "";
                   return (

                    
                      <SoftButton title='Notify' variant="gradient" size="small" color="success"  onClick={this.onClickNotify}>
                      <FaShare></FaShare>
                      </SoftButton>
                      

                    
                    
                   )
                   }

                   
                     

                  })()
        
              }
             
             

{
                (() => {


                  if(this.state.group_admin_flag === '1'){ 

                    var link_to_create = "/makeauction";
                    var querystring = "?id="+btoa(this.state.group_id)+"&sid="+btoa(this.state.series_id);
                    

                   return (

                    <Link to={{pathname:link_to_create,search:querystring} }>
                      <img src={auction_hammer} width='40px' title='Auction' ></img>
                      </Link>

                    
                    
                   )
                   }

                   
                     

                  })()
        
              }





{
                (() => {
                  let search = window.location.search;
                    let params = new URLSearchParams(search);
                    let group_id = atob(params.get('id'));

                  if(this.state.group_admin_flag === '1' ){ 

                  var link_to_create = "/viewmembers";
                  var querystring = "?id="+btoa(this.state.group_id)+"&sid="+btoa(this.state.series_id);
                   return (

                    <Link to={{pathname:link_to_create,search:querystring,state:{viewstatus: 'createlink'}} }>
                    <SoftButton title='Edit this link' variant="gradient" size="small" color="info"  onClick={() => this.setState({viewstatus: 'createlink'})}>
                    
                    <FaPenSquare></FaPenSquare>
                    </SoftButton>
                    </Link>

                    
                    
                   )
                   }

                   
                     

                  })()
        
                }

{
                (() => {
                  let search = window.location.search;
                    let params = new URLSearchParams(search);
                    let invite = params.get('i');

                  if(this.state.group_admin_flag === '1'){ 

                  var link_to_create = "javascript:void(0)";
                  var querystring = "";

                  var link_to_create = "/mi/plain";
                  var querystring = "?i="+invite;

                    

                   return (

                    <Link to={{pathname:link_to_create,search:querystring} }>
                      <img src={whatsapp_logo} width='40px' title='Share to whatsapp' ></img>
                      </Link>

                    
                    
                   )
                   }

                   
                     

                  })()
        
              }

             
              </SoftBox>

              </SoftBox>
              <SoftBox py={3}>
              
              {
            (() =>{
              if(this.state.storedIUserName!=='' && this.state.storedIUserName!= null)
              {
                return (  <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}><small>

                  Quick : <a href='javascript:void(0)' onClick={this.clickedAddMemberQuick}>Add {this.state.storedIUserName}</a>
                  
                  </small></SoftBox>)
              }
            })()
          }
                
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                

                <table class='table table-sm' width="100%">
                  <tr>
                    <td class='det0' width="25%">Series Name </td>
                    </tr><tr>
                    <td class='det1' width="70%">{this.state.series_name}</td>
                    </tr>
                    <tr>
                    <td class='det0'  >Date </td>
                    </tr><tr>
                    <td  class='det1'>{this.state.match_date}, {this.state.match_date_name}</td>
                    </tr>
                    <tr>
                    <td class='det0'>From Time </td>
                    </tr><tr>
                    <td  class='det1'>{this.state.from_time}</td>
                    </tr>
                    {
                    (() => {
                  if(this.state.from_time !== this.state.to_time ){ 
                   return (
                    <tr>
                    <td class='det0'>To Time </td>
                    </tr>
                   )
                  }})()
                }
                {
                    (() => {
                  if(this.state.from_time !== this.state.to_time ){ 
                   return (
                    <tr>
                    <td  class='det1'>{this.state.to_time}</td>
                    </tr>
                   )
                  }})()
                }
                    
                    <tr>
                    <td class='det0'>Maximum Participants   </td>
                    </tr><tr>
                    <td class='det1'>{this.state.total_participants}</td>
                    </tr>
                    <tr>
                    <td class='det0'>Maximum Waiting List  </td>
                    </tr><tr>
                    <td class='det1'>{this.state.total_waiting_list}</td>
                    </tr>

                    {
                    (() => {
                  if(this.state.total_guests_participants !== 0 && this.state.total_participants !== this.state.total_guests_participants ){ 
                   return (
                    <tr>
                    <td class='det0'>Guest Quota </td>
                    </tr>
                   )
                  }})()
                }
                {
                    (() => {
                  if(this.state.total_guests_participants !== 0 && this.state.total_participants !== this.state.total_guests_participants){ 
                   return (
                    <tr>
                    <td  class='det1'>{this.state.total_guests_participants}</td>
                    </tr>
                   )
                  }})()
                }

                    <tr>
                    <td class='det0'>Cut off time  </td>
                    </tr><tr>
                    <td class='det1'>{this.state.add_delete_permission_cutoff_time}</td>
                    </tr>
                    <tr>
                    <td class='det0'>Remaining Slots </td>
                    </tr>
                    <tr>
                    <td class='det1'>{this.state.remaining_slots}</td>
                    </tr>
                    <tr>
                    <td class='det0'>Status </td>
                    </tr>
                    
                    <tr>
                    <td class='det1'>{this.state.status_name}</td>
                    </tr>

                    

                    <tr>
                    <td >
                    {this.state.header_description}
                    <br/>
                    {this.state.footer_description}
                    </td>
                   
                    </tr>


                    {
                    (() => {
                  if(this.state.missing_sl !== ''){ 
                   return (
                    <tr>
                    <td  >Free slot(s): {this.state.missing_sl}</td>
                    </tr>
                   )
                  }})()
                }

                  
                    


                </table>
                </SoftBox>
              </SoftBox>
              

      </Card>
      
        <SoftBox mb={3}>
        {
                (() => {
                  if(this.state.viewstatus === 'list' ){ 
                   return (
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Participants List</SoftTypography>
              
              {
                (() => {
                  if(this.state.show_link === '1' ){ 
                   return (
              

              <SoftButton variant="gradient" size="small" color="success"  onClick={() => this.setState({viewstatus: 'create',member_type:'1',member_type_name:'Normal member'})}>
                Join
              </SoftButton>
              
                   )
                  }else
                  {
                    return (
                      <SoftTypography variant="h6" color="success" >Link closed</SoftTypography>
                    )
                  }
                })()
              }


            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={member_columns} rows={this.state.normalmembers} />

              <SoftTypography variant="h6"><br></br>Waiting List</SoftTypography>
              
              <Table columns={member_columns} rows={this.state.wl_members} />


            </SoftBox>


            

          </Card>
        )}
        else if(this.state.viewstatus === 'createlink' )
        { 
          let search = window.location.search;
                    let params = new URLSearchParams(search);
                    let group_id = atob(params.get('id'));
         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
          Generate new match schedule link
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" id="groupform">
            <SoftBox mb={2}>
              <SoftInput placeholder="League Name" name="s_series_name" id="s_series_name" inputProps={{ maxLength: 100}}  />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="date" name="s_match_date" id="s_match_date" placeholder="Scheduled Date" inputProps={{ maxLength: 10}} />
            </SoftBox>

            <SoftBox mb={2}>
            <SoftInput type="time" name="s_from_time" id="s_from_time" placeholder="From Time" inputProps={{ maxLength: 10}} />
            </SoftBox>

            <SoftBox mb={2}>
            <SoftInput type="time" name="s_to_time" id="s_to_time" placeholder="To Time" inputProps={{ maxLength: 10}} />
            </SoftBox>

            <SoftBox mb={2}>
              <SoftInput placeholder="Header Part" name="s_header_description" id="s_header_description" multiline rows={5}  />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput placeholder="Footer Part" name="s_footer_description" id="s_footer_description" multiline rows={5}  />
            </SoftBox>


            <SoftBox mb={2}>
              <SoftInput placeholder="Total Participants" name="s_total_participants" id="s_total_participants" inputProps={{ maxLength: 3}}  />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput placeholder="Max. Waiting List" name="s_total_waiting_list" id="s_total_waiting_list" inputProps={{ maxLength: 2}}  />
            </SoftBox>
            

            <SoftBox mb={2}>
            <SoftInput type="datetime" name="add_delete_permission_cutoff_mins" id="add_delete_permission_cutoff_mins" placeholder="Cut Off Time for Removing Name?(Enter minutes)" inputProps={{ maxLength: 18}} />
            </SoftBox>


            
            <SoftBox mt={4} mb={1}>
              <input type='hidden' id='s_group_id' value={group_id}></input>
              <SoftButton variant="gradient" color="dark" fullWidth onClick={() => this.setState({viewstatus: 'list'})}>
                Generate
              </SoftButton>
            </SoftBox>
            
          </SoftBox>

          <Link to="/viewmembers?id=21">
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    Back
                  </SoftTypography>
                
              </Link>
              

        </SoftBox>

          </Card>
        
        )
        }
        else if(this.state.viewstatus === 'uploaduserdp' )
        { 
          const link_to = window.location.href;
         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Change Profile Pic - {this.state.selected_member_name}
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" id="midgroupform">
            
          <SoftBox mb={2}>
             <SoftInput type="file" name="midp_profile_pic" id="midp_profile_pic" placeholder="Profile pic" onChange={this.ondMIImageChange}  />
             
             
            </SoftBox>
            
          </SoftBox>
          {
            /*
            viewmembers?id=
            */
          }

          <Link to={link_to}>
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    Back 
                  </SoftTypography>
                
              </Link>
             

        </SoftBox>

          </Card>
        
        )
        }
        else if(this.state.viewstatus === 'uploadusercricdp' )
        { 
          const link_to = window.location.href;
         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Edit Cricheroes Profile - {this.state.selected_member_name}
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" id="midgroupform">
            
          <SoftBox mb={2}>
             <SoftInput type="text" name="micricdp_profile_pic_link" id="micricdp_profile_pic_link" placeholder="Cricheroes Profile link"  />
             
             
            </SoftBox>
            <SoftBox mb={2}>
            <SoftButton variant="gradient" color="dark" id='j_member_add_btn_upd1' fullWidth onClick={this.ondMIICricmageChange}>
                Update
            </SoftButton>
            </SoftBox>

            
          </SoftBox>
          {
            /*
            viewmembers?id=
            */
          }

          <Link to={link_to}>
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    Back 
                  </SoftTypography>
                
              </Link>
             

        </SoftBox>

          </Card>
        
        )
        }
        else if(this.state.viewstatus === 'create' )
        { 
         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Join 
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          {
            (() =>{
              if(this.state.storedIUserName!=='' && this.state.storedIUserName!= null)
              {
                return ( <small>

                  Quick : <a href='javascript:void(0)' onClick={this.clickedAddMemberC}>Add {this.state.storedIUserName}</a>
                  
                  </small>)
              }
            })()
          }
         

          <SoftBox component="form" role="form" id="groupform">
            <SoftBox mb={2}>
              <input type='hidden' id='j_member_id'/>
              <SoftInput placeholder="Mobile Number" onKeyUp={this.onKeyDownSearch} name="j_mobile_no" id="j_mobile_no" inputProps={{ maxLength: 10}}  />
            </SoftBox>

            <SoftBox mb={2} id="j_members_list">
              
              
            </SoftBox>

            {
                (() => {

                  if(this.state.group_admin_flag === '1' || (this.state.show_join_link_group_members_only ==='0' || this.state.show_join_link_group_members_only ==='2') ){ 
  
                     return (
            <SoftBox mb={2}>
              <SoftInput placeholder="Name" name="j_full_name" id="j_full_name" inputProps={{ maxLength: 60}}  />
            </SoftBox>
            
            )
}
})()

}

{
                (() => {

                  if(this.state.photo_upload === '1' && (this.state.group_admin_flag === '1' || this.state.selected_player_photo === ''  )){ 
  
                     return (

                      <SoftBox mb={2}>Upload Photo
              <SoftInput type="file" name="photo_upload" id="photo_upload" placeholder="Upload Photo"  />
            </SoftBox>

            
            )
}
})()

            }

{
                (() => {

                  if(this.state.group_admin_flag === '1'  || (this.state.show_join_link_group_members_only ==='0' || this.state.show_join_link_group_members_only ==='2')){ 
  
                     return (
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="dark" id='j_member_add_btn' fullWidth onClick={this.onClickAddLink}>
                Add
              </SoftButton>
        {this.state.loader_join ? <Spinner /> : null}

            </SoftBox>
                )
              }
              })()
              
              }
              
              <SoftBox display="flex" alignItems="center">
              <Checkbox value="1" id="j_keep"   />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                &nbsp;&nbsp;Need to add multiple? Tick this to keep the form.
              </SoftTypography>
              
            </SoftBox>
            
          </SoftBox>

          <Link to="javascript:void(0)">
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={this.backButtonClick}
                  >
                    Back to list
                  </SoftTypography>
                
              </Link>
              

        </SoftBox>

          </Card>
        
        )
        }

        else if(this.state.viewstatus === 'edit' )
        { 
         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Update Group 
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" id="groupform">
            <SoftBox mb={2}>
              Group Name
              <SoftInput placeholder="Name" name="group_name" id="group_name" inputProps={{ maxLength: 60}}  />
            </SoftBox>
            <SoftBox mb={2}>
              Location
              <SoftInput type="text" name="location" id="location" placeholder="Location" inputProps={{ maxLength: 100}} />
            </SoftBox>
            <SoftBox mb={2}>Upload Logo
              <SoftInput type="file" name="group_logo" id="group_logo" placeholder="Upload Logo"  />
            </SoftBox>

            <SoftBox mb={2}>Fetch Telegram Main Group Name
              <SoftInput type="text" name="main_telegram_chat_id" id="main_telegram_chat_id" placeholder="Fetch Telegram Main Group Name"  inputProps={{ maxLength: 30,readOnly:true}} />
            </SoftBox>
            <SoftBox mb={2}>Fetch Telegram Guest Group Name
              <SoftInput type="text" name="guest_telegram_chat_id" id="guest_telegram_chat_id" placeholder="Fetch Telegram Guest Group Name"  inputProps={{ maxLength: 30,readOnly:true}} />
            </SoftBox>
            
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="dark" fullWidth onClick={() => this.setState({viewstatus: 'list'})}>
                Update
              </SoftButton>
            </SoftBox>
            
          </SoftBox>

          <Link to="/groups">
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    Back to list
                  </SoftTypography>
                
              </Link>

        </SoftBox>

          </Card>
        
        )
        }

        else if(this.state.viewstatus === 'invite_link' )
        { 
         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Generate Invite Links
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>

        <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            > Generated Links
              <Table columns={links_columns} rows={linkrows} />
            </SoftBox>

            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            > 
            <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    Generate a new invite link
                  </SoftTypography>

            </SoftBox>

          <SoftBox component="form" role="form" id="groupform">
            <SoftBox mb={2}>
              <SoftInput placeholder="Max Limit" name="max_join_limit_with_link" id="max_join_limit_with_link" inputProps={{ maxLength: 4}}  />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="text" name="link_expiry" id="link_expiry" placeholder="Link Expiry Date" inputProps={{ maxLength: 10}} />
            </SoftBox>
            
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="dark" fullWidth onClick={this.onClickGenerateLink}>
                Generate
              </SoftButton>
            </SoftBox>
            
          </SoftBox>

          <Link to="/viewmembers?id=21">
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    Back to members list
                  </SoftTypography>
                
              </Link>
              

        </SoftBox>

          </Card>
        
        )
        }

        
      })()
        
        }
        </SoftBox>

      
      </SoftBox>
      
      <Modal
        isOpen={this.state.show}
        onRequestClose={this.closeModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <h2 >Profile</h2>
        
        <button variant="button" fontWeight="medium" color="info" onClick={this.closeModal}>close</button>
        <div id='i_profile_modal'>Player profile </div>
        
      </Modal>


      <Modal
          isOpen={this.state.showVerify}
          onRequestClose={this.closeshowVerify}
          style={customStyles}
          shouldCloseOnOverlayClick={true}
          contentLabel="Modal"
        >

          <div id='i_remove_modal'>
          {this.state.pop_message}
          <div id='i_remove_modal_click'>
          <SoftButton variant="gradient" color="dark"  onClick={this.onClickGenOTPR}>
                Generate OTP
              </SoftButton>

          </div>
          <div id='i_remove_modal_form' style={{display:"none"}} >
      <SoftBox p={3} mb={1} textAlign="center">
        <SoftTypography variant="h5" fontWeight="medium">
          Verify OTP 
        </SoftTypography>

        <SoftBox mb={2}>
              <SoftInput type="text" name="rem_otp" id="rem_otp" placeholder="Please enter OTP" inputProps={{ maxLength: 4}} />
            </SoftBox>

      </SoftBox>

      <SoftButton variant="gradient" color="dark" fullWidth onClick={this.onRClickVerify}>
                Verify
              </SoftButton>

              </div>

          </div>


        </Modal>


        <Modal
          isOpen={this.state.showChangeType}
          onRequestClose={this.closeshowChangeType}
          style={customStyles}
          shouldCloseOnOverlayClick={true}
          contentLabel="Modal"
        >

          <div id='i_changetype_modal'>
          {this.state.change_pop_message}
          <div id='i_changetype_modal_click'>

        <SoftBox>
          Type: 
          <FormSelect size="sm" className="mb-9" name="mi_member_type" id="mi_member_type" >
        <option value="1" selected={(this.state.c_participant_member_type === "1") ? 'selected' : ''}>Normal</option>
        <option value="2" selected={(this.state.c_participant_member_type === "2") ? 'selected' : ''}>Guest</option>
      </FormSelect>
      </SoftBox>

      <SoftBox>
        Icon ?
          <FormSelect size="sm" className="mb-9" name="mi_is_icon" id="mi_is_icon" >
        <option value="0" selected={(this.state.c_is_icon === "0") ? 'selected' : ''}>No</option>
        <option value="1" selected={(this.state.c_is_icon === "1") ? 'selected' : ''}>Yes</option>
      </FormSelect>
      </SoftBox>
      <SoftBox>
      <SoftInput placeholder="Icon Team Name" name="mi_icon_text" id="mi_icon_text" onChange={this.handleChange} value={this.state.c_icon_tex} inputProps={{ maxLength: 100}}  />
      </SoftBox>

      <SoftBox>
          <SoftButton variant="gradient" color="dark"  onClick={this.onClickMChangeType}>
                Update
              </SoftButton>
              </SoftBox>
          </div>
          

          </div>


          {
                (() => {


                  if(this.state.group_admin_flag === '1'){ 
                    return (<div><br></br><hr></hr><br></br><br></br>Swap player:<br></br>
                    
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <td>
                    <SoftInput  placeholder="Enter mobile number" name="mi_e_new_user_mobile_number" id="mi_e_new_user_mobile_number" inputProps={{ maxLength: 10 }} />
                    
                    
                          </td>
                          <td>
                          <SoftButton variant="gradient" id="michangeswapplayer" size="small" color="success" title="Change Player"
                            onClick={this.onClickSwapPlayerBeforeAuction}>
                            Change to a new player
                          </SoftButton>
                          </td>
                          </tr>

                          </tbody>
                          </table>
                    
                    </div>)
                  }
                })()
        }


        </Modal>


      <Footer />
      
    </DashboardLayout>
    )  
  }  
}  

