/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import React from 'react';  

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { Helmet } from "react-helmet";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import axios from 'axios';  
import RECAPTCHA from "react-google-recaptcha"
import { useRef} from 'react';
import loader_img from "assets/images/loader.svg";

const Spinner = () => (
  <img src={loader_img} class="zoom2" height="30"/>
)
export default class SingIN extends React.Component {  
  constructor(props) {
    super(props);
    axios.defaults.withCredentials = true;
    this.ref_full_name = React.createRef();
    this.ref_mobile_no = React.createRef();
    this.ref_password = React.createRef();
    this.ref_email = React.createRef();
    this.captchaRef= React.createRef();
    
    this.ref_error_msg = React.createRef();
    //this.ref_full_name.current = '';
    this.setState({error_msg: ''});
    
  }
  

componentDidMount() {  

//  const [rememberMe, setRememberMe] = useState(true);

  //const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const post_data = { lo : 1};

  axios.post( global.config.app.backend_api_url + `login/sessionavailable`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      console.log(res.data)
      var user_id = res.data.user_details.user_id;
      if (user_id !== undefined && user_id !== null && user_id !== 'null') 
        {
          window.location.replace('groups');
        }else
        {
          var storedMobileNumber = localStorage.getItem("storedMobileNumber");
        var storedPassword = localStorage.getItem("storedPassword");

        // Autofill fields if values exist
        if (storedMobileNumber !== null) {
          document.getElementById("mobile_no").value = storedMobileNumber;
        }
        if (storedPassword !== null) {
          document.getElementById("password").value = storedPassword;
        }
        
        }
        
       

    });
  }

checksignin= (e)=> 
{
  e.preventDefault();
  //this.setState({loader_join: true});
  var mobile_no = document.getElementById("mobile_no").value;
  var password = document.getElementById("password").value;

  const token = this.captchaRef.current.getValue();
  this.captchaRef.current.reset();

  const post_data = { mobile_no: mobile_no,password:password ,gtoken:token};
      
  //      alert(group_id)
  //,{headers: {"Content-Type": "application/json"}}
      axios.post( global.config.app.backend_api_url + `login/check`,post_data, { withCredentials: true }
      )  
        .then(res => {  
          //const normalmembers = res.data;  
          console.log("after112")
          console.log(res.data);
          var status = res.data.status;
          var message = res.data.message;
          //this.setState({loader_join: false});
          if(status =='0')
          {
            alert("message:"+message)
          }
          else
          {
            localStorage.setItem("storedMobileNumber", mobile_no);
            localStorage.setItem("storedPassword", password);

            let search = window.location.search;
            let params = new URLSearchParams(search);
            let redirection = atob(params.get('r'));
            if(params.get('r')!=null)
            {
              window.location.replace(redirection);
            }
            else{
              window.location.replace('groups');

            }
          }
  
        });

}


    render() {  
      return (  
    <CoverLayout
      title="Welcome back"
      description="Enter your mobile number and password to sign in"
      image={curved9}
    >
      <Helmet>
      <title>Sign In | {global.config.app.name}</title>
      <meta
        name="description"
    content="Join Our Cricket Team - Experience the thrill of competitive cricket and be part of a supportive community. Sign up now and showcase your skills on the field."
      />
      <meta
        name="keywords"
        content="Cricket, team, join, competitive, skills, community, sign up, field"
      />
    </Helmet>

      <SoftBox component="form" role="form" autocomplete="on">
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Mobile No.
            </SoftTypography>
          </SoftBox>
          <SoftInput autoComplete="on" type="text" placeholder="Mobile No." id="mobile_no" inputProps={{ maxLength: 10}}  />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput autoComplete="on" type="password" placeholder="Password" id="password" inputProps={{ maxLength: 20}}  />
        </SoftBox>
        {/*
        <SoftBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </SoftTypography>
        </SoftBox>*/}
        <SoftBox mb={2}>
            <RECAPTCHA sitekey="6LehUMomAAAAAJl1yE63intOIHyqyPNwpqUQZ_gG"
            ref={this.captchaRef }

            />
            </SoftBox>

        <SoftBox mt={4} mb={1}>
          <SoftButton type="submit" variant="gradient" color="info" fullWidth
          onClick={this.checksignin}>
            sign in
          </SoftButton>

        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SoftTypography
              component={Link}
              to="/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SoftTypography>
          </SoftTypography>
        </SoftBox>

        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            
            <SoftTypography
              component={Link}
              to="/forgot-my-password"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Forgot my password ?
            </SoftTypography>
            
          </SoftTypography>
        </SoftBox>

      </SoftBox>
    </CoverLayout>
  )  
  }  
}  

