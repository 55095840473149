
import axios from 'axios';  
import {  Link } from "react-router-dom";
import { io } from "socket.io-client";

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { Helmet } from "react-helmet";

// Soft UI Dashboard React examples
//import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardLayout from "examples/LayoutContainers/FullDashboardLayout";


import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftInput from "components/SoftInput";
import Box from '@mui/material/Box';

// @mui material components
import Card from "@mui/material/Card";

import Grid from "@mui/material/Grid";

import Parser from 'html-react-parser';
import SoftButton from "components/SoftButton";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import team2 from "assets/images/team-2.jpg";

//import Modal from 'react-bootstrap/Modal';

// Data
//import membersTableData from "layouts/groups/data/membersTableData";

import SoftAvatar from "components/SoftAvatar";
import AdComponent from "layouts/liveauction/common/AdComponent";
// Images
import 'auction.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles


import React, { useState, useEffect } from "react";
import FormSelect from 'react-bootstrap/FormSelect';
import { FaBackward } from "react-icons/fa";
import { FaPenSquare } from "react-icons/fa";

import { Swiper, SwiperSlide } from 'swiper/react';

import { Navigation, Pagination, Scrollbar, A11y,Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    width:'60%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {zIndex: 100000}   
};

const socket = io("https://auctions.4cr.in", {
  reconnection: true, // Enable automatic reconnection
  reconnectionAttempts: 5, // Max attempts before giving up
  reconnectionDelay: 1000, // Delay between reconnection attempts (ms)
  reconnectionDelayMax: 5000, // Max delay between attempts (ms)
  autoConnect: true, // Automatically connect
});


const data = [
    { option: "0", style: { backgroundColor: "green", textColor: "white" } },
    { option: "1", style: { backgroundColor: "red", textColor: "white" } },
    { option: "3", style: { backgroundColor: "blue", textColor: "white" } },
    { option: "4", style: { backgroundColor: "pink", textColor: "white" } },
    { option: "5", style: { backgroundColor: "orrange", textColor: "white" } },
    { option: "6", style: { backgroundColor: "gray", textColor: "white" } },
    { option: "7", style: { backgroundColor: "black", textColor: "white" } },
    { option: "8", style: { backgroundColor: "red", textColor: "white" } }
    
  ];

  
  function updateTeamList(playerAmount) {
    // Get all elements with the class 'teamlist_amount'
    const teamListElements = document.querySelectorAll('.teamlist_amount');

    teamListElements.forEach(element => {
        const amount = parseFloat(element.textContent.trim()); // Parse text as a number
        console.log(element)
        // Check if player amount is greater than or equal to the team's amount
        if (playerAmount >= amount) {
            element.classList.add('disabled_team'); // Add class to parent or desired container
        } else {
            element.classList.remove('disabled_team'); // Remove class if condition no longer matches
        }
    });
}

  function UnsoldPlayer({ user_id, player_name, sl_no, profile_pic, cric_heroes_profile_link, noGutter }) {
    return (
      <SoftBox
        mb={noGutter ? 0 : 1}
      >
            <SoftTypography variant="caption" color="text">
              <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="capitalize">
              {sl_no}. {player_name} 
              </SoftTypography>
              &nbsp;
              
            </SoftTypography>
       
      </SoftBox>
    );
  }
function Team({ sl_no,team_name, team_logo, current_wallet_balance, current_players_count, players, max_bid_for_a_player, noGutter, linktoteam, max_wallet_size,max_players_count }) {

  var linktoteam1 = '/teamplayers';
  var qrystring = '?t=' + linktoteam;
  const percentage = 66;

  const valuep =`${current_players_count}/${max_players_count}`;
  const vpercentage = ((current_players_count / max_players_count) * 100).toFixed(2); // Keeps two decimal places

  return (
    <Grid xs={12} md={12} style={{ border: '1px solid rgba(0, 0, 0, 0.05)' ,
    backgroundColor: sl_no % 2 === 0 ? '#f0f0f0' : '#fffffe', // Light hash color
    padding: '10px',
    borderRadius: '8px',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.3s',
    ':hover': {
      backgroundColor: '#b2ebf2', // Darker hash color on hover
      transform: 'scale(1.05)',
    },
    
    }}>

      <SoftBox display="flex" p={1}>
        <a name={team_name}></a>
        <SoftAvatar
          src={team_logo ? (team_logo) : (team2)}
          alt="profile-image"
          variant="rounded"
          size="sm"
          shadow="sm"
        />
        &nbsp;
        <Link to={{ pathname: linktoteam1, search: qrystring }}>
          {team_name}
        </Link>
      </SoftBox>

      <SoftBox mb={3}>


      <table style={{ width: "100%", fontSize: "14px" }} border="0" cellPadding="3" cellSpacing="3">
                  <tbody>
                    <tr>
                      <td style={{  padding: "4px",   paddingBottom : "5px" , wordBreak : "break-all" }}>Current Wallet Balance</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>:</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>{current_wallet_balance}</td>
                      <td style={{  padding: "4px",  paddingBottom : "5px"  , wordBreak : "break-all" }}>Maximum Wallet </td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>:</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>{max_wallet_size}</td>
                      
                    </tr>

                    <tr>
                      <td style={{  padding: "4px",   paddingBottom : "5px" , wordBreak : "break-all"  }}>Maximum bid for a player</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>:</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" , color : "red" }}>{max_bid_for_a_player}</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" , wordBreak : "break-all"  }}>Current Count</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>:</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>
                      <div style={{ width: 40, height: 40 }}>
                        <CircularProgressbar value={vpercentage} text={`${valuep}`} />
                      </div>
                        </td>
                      
                    </tr>

                  </tbody>
        </table>
        </SoftBox>
 
    
      <SoftTypography>
        {
          (() => {
            const listitems = players;
            if (typeof (listitems) != "undefined") {
              return (
                <table style={{ width: "100%", borderCollapse: "collapse", fontSize: "14px" }}>
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid black", padding: "4px",width:"5%" }}>#</th>
                      <th style={{ border: "1px solid black", padding: "4px",width:"45%" }}>Name</th>
                      <th style={{ border: "1px solid black", padding: "4px",width:"15%"}}>Auction Sold Amount</th>
                      <th style={{ border: "1px solid black", padding: "4px",width:"5%" }}>PDF Sl No</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {listitems.map((player, index) => (
                      <tr key={index}>
                        <td style={{ border: "1px solid black", padding: "4px" }}>{index + 1}</td>
                        
          

                        <td style={{ border: "1px solid black", padding: "4px" }}><img
            src={player.profile_pic}
            alt={player.full_name}
            className="me-3"
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          />{player.full_name} {player.captain_flag == '1' ? '(C)' : ''} {player.last_sold_status_flag == 1 ? <span style={{ color: 'red' }}>***</span> : ''}</td>
                        <td style={{ border: "1px solid black", padding: "4px" }}>{player.auction_sold_amount}</td>
                        <td style={{ border: "1px solid black", padding: "4px" }}>{player.sl_no}</td>
                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              );
            }
          })()
        }
      </SoftTypography>
    </Grid>
  );
}

const CurrentPlayerCard = ({ player }) => {
  const [blink, setBlink] = useState(false);

  useEffect(() => {
    if (player.amount) {
      setBlink(true);
      const timer = setTimeout(() => setBlink(false), 1000); // Remove blink after 1 second
      return () => clearTimeout(timer);
    }
  }, [player.amount]); // Trigger effect when player.amount changes

  return (
    <div className="container mt-12">
      <div className="row align-items-center border rounded shadow-sm p-3 bg-white">
        <div className="col-3">
          <img
            src={player.img}
            alt={player.name}
            className="img-fluid rounded"
            style={{ maxHeight: '100px' }}
          />
        </div>
        <div className="col-9">
          <h5 className="mb-1">{player.name}&nbsp;&nbsp;[{player.pdf_sl_no}]</h5>
          <p
            className={`mb-0 text-success ${blink ? "blink" : ""}`}
          >
            Current Bid: {player.amount}
          </p>
        </div>
      </div>
    </div>
  );
};

const SoldDisplay = ({ player }) => {
  
  return (
    <div className="container mt-12">
        <div className="col-3">
          <img
            src={player.img}
            alt={player.name}
            className="img-fluid rounded"
            style={{ maxHeight: '100px' }}
          />
        </div>
        <div className="col-9">
          <h5 className="mb-1">{player.name}&nbsp;&nbsp;[{player.pdf_sl_no}]</h5>
          <p
            className={`mb-0 text-success`}
          >
            Sold to {player.team} for {player.amount}
          </p>
        </div>
      </div>
  );
};


const ChangeTeamDisplay = ({ player }) => {
  
  return (
    <div className="container mt-12">
        <div className="col-3">
          <img
            src={player.img}
            alt={player.name}
            className="img-fluid rounded"
            style={{ maxHeight: '100px' }}
          />
        </div>
        <div className="col-9">
          <h5 className="mb-1">{player.name}&nbsp;&nbsp;[{player.pdf_sl_no}]</h5>
          <p
            className={`mb-0 text-success`}
          >
            Edited
          </p>
        </div>
      </div>
  );
};

const UndosoldDisplay = ({ player }) => {
  
  return (
    <div className="container mt-12">
        <div className="col-3">
          <img
            src={player.img}
            alt={player.name}
            className="img-fluid rounded"
            style={{ maxHeight: '100px' }}
          />
        </div>
        <div className="col-9">
          <h5 className="mb-1">{player.name}&nbsp;&nbsp;[{player.pdf_sl_no}]</h5>
          <p
            className={`mb-0 text-success`}
          >
            Reversed
          </p>
        </div>
      </div>
  );
};


const NewPlayerDisplay = ({ player }) => {
  
  return (
    <div className="container mt-12">
        <div className="col-3">
          <img
            src={player.img}
            alt={player.name}
            className=" rounded"
            style={{ maxHeight: '400px' ,maxWidth: '400px' }}
          />
        </div>
        <div className="col-9">
          <h5 className="mb-1">{player.name}&nbsp;&nbsp;[{player.pdf_sl_no}]</h5>
          <p
            className={`mb-0 text-success`}
          >
            New Player
          </p>
        </div>
      </div>
  );
};

const UnsoldDisplay = ({ player }) => {
  
  return (
    <div className="container mt-12">
        <div className="col-3">
          <img
            src={player.img}
            alt={player.name}
            className="img-fluid rounded"
            style={{ maxHeight: '100px' }}
          />
        </div>
        <div className="col-9">
          <h5 className="mb-1">{player.name}&nbsp;&nbsp;[{player.pdf_sl_no}]</h5>
          <p
            className={`mb-0 text-success`}
          >
            Player unsold
          </p>
        </div>
      </div>
  );
};

class LiveAuction extends React.Component {
  constructor(props) {
    super(props);

    let search = window.location.search;
      let params = new URLSearchParams(search);
      let invite_code = params.get('i');

    const auctionId = invite_code;
        this.state = { x: false,sx: false,main_auction_details:{group_name:'Group Name'},spin_data:data,counter:0
      ,lastClickTime: null,
      timeAgo: "",auctionId:auctionId,messages: [],
      playerName: "",
      startingBid: "",
      finalBid: "",
      soldTo: ""};


    window.helloComponent = this;

    this.callbasicapi = this.callbasicapi.bind(this);
//    this.setState({x: false,sx: false});

  }

 onEditSellModal  = (sl_no,player_name,team_id,sold_amount,user_id,e)=> 
 {
    this.setState({showEditSell: !this.state.showEditSell,
    e_selected_sl:sl_no,
    e_selected_player:player_name,
    e_team_id:team_id,
    e_sold_amount:sold_amount});

    setTimeout(
        () =>     document.getElementById('e_a_match_team_id').value = team_id, 
        700
    );

   
 };

  //const [x, sx] = React.useState(false);



  componentDidMount() {

    const { auctionId } = this.state;

    // Join the auction room
    socket.emit("joinAuction", auctionId);
    console.log("Joined auction room:", auctionId);

    // Listen for auction events
    socket.on("auctionEvent", this.handleAuctionEvent);

    // Handle reconnection success
    socket.on("connect", () => {
      console.log("Socket connected:", socket.id);
      socket.emit("joinAuction", auctionId); // Rejoin the room after reconnection
    });

    // Handle disconnection
    socket.on("disconnect", () => {
      console.log("Socket disconnected. Attempting to reconnect...");
    });

    // Handle reconnection attempts
    socket.on("reconnect_attempt", (attempt) => {
      console.log(`Reconnection attempt ${attempt}...`);
    });

    // Handle reconnection failure
    socket.on("reconnect_failed", () => {
      console.log("Reconnection failed.");
    });

    // Handle visibility changes
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
 

    this.callbasicapi();
    
    /*
    this.interval = setInterval(() => {
      this.callbasicapi();
    }, 30000); // 30 seconds in milliseconds


    this.timeout = setTimeout(() => {
      clearInterval(this.interval);
      //this.reconfirmUser();

      toast.error('Please refresh the page', {
        position: "top-right",
        autoClose: false, // Toast disappears in 1 second
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });

    }, 1200000); // 20 minutes in milliseconds  1200000
    */


    this.interval = setInterval(() => {
      const { lastClickTime } = this.state;
      if (lastClickTime) {
        const secondsAgo = Math.floor((new Date() - lastClickTime) / 1000);

        let timeAgo;
        if (secondsAgo < 60) {
          timeAgo = `${secondsAgo} second${secondsAgo !== 1 ? "s" : ""} ago`;
        } else if (secondsAgo < 3600) {
          const minutesAgo = Math.floor(secondsAgo / 60);
          timeAgo = `${minutesAgo} minute${minutesAgo !== 1 ? "s" : ""} ago`;
        } else {
          const hoursAgo = Math.floor(secondsAgo / 3600);
          timeAgo = `${hoursAgo} hour${hoursAgo !== 1 ? "s" : ""} ago`;
        }

        this.setState({ timeAgo });
      }
    }, 1000);


  }
  componentWillUnmount() {

    socket.off("auctionEvent", this.handleAuctionEvent);
    socket.off("connect");
    socket.off("disconnect");
    socket.off("reconnect_attempt");
    socket.off("reconnect_failed");

    // Remove visibility change listener
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );

    clearInterval(this.interval);
    //clearInterval(this.interval);
  }

  handleAuctionEvent = ({ eventType, data }) => {
    const message = this.handleEventMessage(eventType, data);


    
    console.log("handleAuctionEvent1")
    console.log(eventType)
    console.log(data)
    if(eventType==="Unsold")
    {
      //toast
      const customId = Math.floor(10000 + Math.random() * 90000).toString();
      toast.error(message, {
        position: "top-right",
        autoClose: 7000, // Toast disappears in 1 second
        hideProgressBar: false,
        pauseOnFocusLoss : false,
        closeOnClick: true,
        toastId: customId,
        pauseOnHover: false,
        draggable: false,
      });
      //this.callbasicapi(0);

    }
    if(eventType==="ChangeTeam")
    {
      //toast
      const customId = Math.floor(10000 + Math.random() * 90000).toString();
      toast.warn(message, {
        position: "top-right",
        autoClose: 7000, // Toast disappears in 1 second
        hideProgressBar: false,
        pauseOnFocusLoss : false,
        closeOnClick: true,
        toastId: customId,
        pauseOnHover: false,
        draggable: false,
      });

      var auction_details  = data.auction_details;
        var main_auction_details  = auction_details.main[0];
        var teams_details  = auction_details.teams;
        var pending_players  = auction_details.pending;
        
        var top_sell  = auction_details.top_sell;
        var recent_sell = auction_details.recent_sell;

        console.log(main_auction_details);

      this.setState( {counter : this.state.counter + 1, main_auction_details: main_auction_details,teams_details:teams_details,
        pending_players:pending_players,top_sell:top_sell,recent_sell:recent_sell,lastClickTime: new Date(),
        messages: []});

      //this.callbasicapi(0);

    }
    

    if(eventType==="UndoSold")
    {
      //toast
      const customId = Math.floor(10000 + Math.random() * 90000).toString();
      toast.info(message, {
        position: "top-right",
        autoClose: 7000, // Toast disappears in 1 second
        hideProgressBar: false,
        pauseOnFocusLoss : false,
        closeOnClick: true,
        toastId: customId,
        pauseOnHover: false,
        draggable: false,
      });

      var auction_details  = data.auction_details;
        var main_auction_details  = auction_details.main[0];
        var teams_details  = auction_details.teams;
        var pending_players  = auction_details.pending;
        
        var top_sell  = auction_details.top_sell;
        var recent_sell = auction_details.recent_sell;

        console.log(main_auction_details);

      this.setState( {counter : this.state.counter + 1, main_auction_details: main_auction_details,teams_details:teams_details,
        pending_players:pending_players,top_sell:top_sell,recent_sell:recent_sell,lastClickTime: new Date(),
        messages: []});

      //this.callbasicapi(0);

    }
    else if(eventType==="playerSold")
    {
      //toast

      const customId = Math.floor(10000 + Math.random() * 90000).toString();
      toast.success(message, {
        position: "top-right",
        autoClose: 7000, // Toast disappears in 1 second
        hideProgressBar: false,
        pauseOnFocusLoss : false,
        closeOnClick: true,
        toastId: customId,
        pauseOnHover: false,
        draggable: false,
      });
      console.log("main_auction_details")
      console.log(data);

      var auction_details  = data.auction_details;
        var main_auction_details  = auction_details.main[0];
        var teams_details  = auction_details.teams;
        var pending_players  = auction_details.pending;
        
        var top_sell  = auction_details.top_sell;
        var recent_sell = auction_details.recent_sell;

        console.log(main_auction_details);

      this.setState( {counter : this.state.counter + 1, main_auction_details: main_auction_details,teams_details:teams_details,
        pending_players:pending_players,top_sell:top_sell,recent_sell:recent_sell,lastClickTime: new Date(),
        messages: []});

      //this.callbasicapi(0);

    }
    else if(eventType==="NewPlayer")
    {
      //toast

      const customId = Math.floor(10000 + Math.random() * 90000).toString();
      toast.info(message, {
        position: "top-center",
        autoClose: 7000, // Toast disappears in 1 second
        hideProgressBar: false,
        pauseOnFocusLoss : false,
        closeOnClick: true,
        toastId: customId,
        pauseOnHover: false,
        style: {
          width: '500px', // Adjust width as needed
        },
        draggable: false,
        
      });

    }
    else if(eventType==="biddingStart")
    {
      if (data && data.amount !== undefined) {
        updateTeamList(data.amount); // Update the team list based on player amount
      }

      this.setState((prevState) => ({
        messages: [...prevState.messages, message],
      }));
    }

    
  };

  handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      console.log("App in foreground. Ensuring socket is connected...");
      if (!socket.connected) {
        socket.connect(); // Reconnect if necessary
      }
    } else {
      console.log("App in background.");
    }
  };

  
  handleEventMessage(eventType, data) {
    switch (eventType) {
      case "NewPlayer":
        return (<NewPlayerDisplay player={data} />);
      case "biddingStart":
        return (<CurrentPlayerCard player={data} />);
      case "Unsold":
          return (<UnsoldDisplay player={data} />);
          
      case "ChangeTeam":
          return (<ChangeTeamDisplay player={data} />);
            
      case "UndoSold":
          return (<UndosoldDisplay player={data} />);
            

      case "playerSold":
        return (<SoldDisplay player={data} />);
      default:
        return "Unknown event";
    }
  }

  emitEvent = (eventType) => {
    const { auctionId, playerName, startingBid, finalBid, soldTo } = this.state;

    const data = {
      playerName,
      startingBid,
      finalBid,
      soldTo,
    };

    socket.emit("auctionEvent", {
      auctionId,
      eventType,
      data,
    });
    console.log(`Event emitted: ${eventType}`, data);
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };


  reconfirmUser = () => {
    // Ask user if they want to stay on the page
    const stayOnPage = window.confirm("Do you want to stay on this page?");
    if (stayOnPage) {
      // Restart the 20 minutes interval again if they want to stay
      this.interval = setInterval(() => {
        this.callbasicapi();
      }, 30000); // Restart 30 second API calls
      this.timeout = setTimeout(() => {
        clearInterval(this.interval);

        //this.reconfirmUser();
      }, 1200000); // Restart the 20 minute timeout
    } else {
      // Handle navigation or action when user does not want to stay
      console.log('User chose to leave');
    }
  }



  callbasicapi( popflag = 1 )
  {
    let search = window.location.search;
      let params = new URLSearchParams(search);
      let invite_code = params.get('i');

    
      const post_data = { invite_code: invite_code };
      const headers = {
      'Content-Type': 'application/json'
      }
      
    axios.post( global.config.app.backend_api_url + `auction/get_current_auction_status_by_invite_code/`,post_data, {headers: headers, withCredentials: true }
    )  
      .then(res => {  
        //const normalmembers = res.data;  
       // console.log("after112d")
        var status  = res.data.status;
        var message  = res.data.message;
        if(status==0)
        {
          //console.log(message);
          //return
        }
        var auction_details  = res.data.auction_details;
        var main_auction_details  = auction_details.main[0];
       // console.log(main_auction_details)
        var teams_details  = auction_details.teams;
//        var sold_players  = auction_details.sold;
        var pending_players  = auction_details.pending;
        
        var top_sell  = auction_details.top_sell;
        var recent_sell = auction_details.recent_sell;
//      
        
        this.setState( {counter : this.state.counter + 1, main_auction_details: main_auction_details,teams_details:teams_details,
        pending_players:pending_players,top_sell:top_sell,recent_sell:recent_sell,lastClickTime: new Date(),
        end_flag : main_auction_details.end_flag
      });

        if(this.state.end_flag==  0)
        {
          socket.off("auctionEvent", this.handleAuctionEvent);
          socket.off("connect");
          socket.off("disconnect");
          socket.off("reconnect_attempt");
          socket.off("reconnect_failed");

          // Remove visibility change listener
          document.removeEventListener(
            "visibilitychange",
            this.handleVisibilityChange
          );
          
        }

      });
      const customId = Math.floor(10000 + Math.random() * 90000).toString();

      toast.success('Refreshed!', {
        position: "top-right",
        autoClose: 1000, // Toast disappears in 1 second
        hideProgressBar: false,
        pauseOnFocusLoss : false,
        closeOnClick: true,
        toastId: customId,
        pauseOnHover: false,
        draggable: false,
      });
     // console.log("Refreshed")

   
  }
    

  render() {
    const { timeAgo, lastClickTime } = this.state;

    const { auctionId, messages, playerName, startingBid, finalBid, soldTo } =
    this.state;

    return (

      <DashboardLayout>
      <Helmet>
      <title>Auction | {global.config.app.name}</title>
      <meta
        name="description"
    content="Join Our Cricket Team - Experience the thrill of competitive cricket and be part of a supportive community. Sign up now and showcase your skills on the field."
      />
      <meta
        name="keywords"
        content="Cricket, team, join, competitive, skills, community, sign up, field"
      />
    </Helmet>
      <Card>
      <div className="refresh-icon">
        <a
          href="#"
          className="btn btn-outline-secondary rounded-circle shadow"
          onClick={this.callbasicapi}
        >
          🔄
        </a>
        {lastClickTime && (
          <p className="mt-2 text-muted">{timeAgo}</p>
        )}
      </div>
      <SoftBox my={3}>
          <Grid container spacing={3}>
            
<Grid item xs={12} md={12}>
   <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
    <table width="100%">
    <tbody>
    <tr>
    <td colSpan='4' style={{color:'red'}}>{this.state.main_auction_details.group_name} - {this.state.main_auction_details.series_name}</td>
    </tr>
    <tr>
                        <td>
                          No. of Teams:</td>
                        <td>
                          {this.state.main_auction_details.no_of_teams}
                        </td>
                        <td>
                          Players in auction:</td>
                        <td>
                          {this.state.main_auction_details.count_players_in_auction}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Team wallet size:</td>
                        <td>
                          {this.state.main_auction_details.team_max_pocket_size}
                        </td>
                        <td>
                          Player base price:</td>
                        <td>
                          {this.state.main_auction_details.player_base_price}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Pending players:</td>
                        <td>
                          <a href="#Pending">{this.state.main_auction_details.pending_count}</a>
                        </td>
                        <td>
                          Unsold players:</td>
                        <td>
                          {this.state.main_auction_details.unsold_count}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Sold players:</td>
                        <td>
                          {this.state.main_auction_details.sold_count}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
    
     
    </tbody>
    </table>

    </SoftBox>

   


{messages.length > 0 ? (
<div class="col-12" style={{ border: "1px solid #ccc", padding: "10px", minHeight: "50px", marginBottom: "20px" }}>
  {messages.length > 0 ? (
    <div>{messages[messages.length - 1]}</div>
  ) : (
    <div>No messages yet</div>
  )}
</div>
):(<></>)}



{
  this.state.end_flag ==  0 ?(

<SoftBox display="flex" justifyContent="space-between" pb={0} alignItems="center" p={1}>

<table style={{fontSize:"16px",border: "1px solid #ddd"}} >
                        <thead>
                            <tr>
                                <th style={{fontSize:"16px",border: "1px solid #ddd"}}>Team Name</th>
                                <th style={{fontSize:"16px",border: "1px solid #ddd"}}>Count</th>
                                <th style={{fontSize:"16px",border: "1px solid #ddd"}}>Max</th>
                            </tr>
                        </thead>
                        <tbody>

                        {
                    (() => {
                      const listitems = this.state.teams_details;
                      //console.log(listitems);
                      //console.log("populating")
                      if (typeof (listitems) != "undefined") {
                        //console.log(listitems)
                        const rows = [];
                        for (let i = 0; i < listitems.length; i++) {

                          var valuep = listitems[i].current_players_count +'/'+ listitems[i].team_max_playes_count;
                          var vpercentage = ((listitems[i].current_players_count / listitems[i].team_max_playes_count) * 100).toFixed(2); // Keeps two decimal places
                        
                          //team_name,  team_logo, current_wallet_balance, current_players_count, players
                          
                            
                            const isTeamComplete = listitems[i].team_max_playes_count === listitems[i].current_players_count;

                            const class1 = (isTeamComplete ? "teamlist_right teamlist_completed" : "teamlist_right ");
                            const amountClass = (isTeamComplete ? "teamlist_right teamlist_amount teamlist_completed" : "teamlist_right teamlist_amount");
                            const link1 = '#'+listitems[i].team_name;
                            rows.push(
                            <tr >
                            <td className={class1} style={{border: "1px solid #ddd"}}><a href={link1}>{listitems[i].team_name}</a></td>
                                  <td className={class1} style={{border: "1px solid #ddd"}}>
                                    
                                  <div style={{ width: 40, height: 40 }}>
                        <CircularProgressbar value={vpercentage} text={`${valuep}`} />
                      </div>
                      </td>
                                  <td  className={amountClass} style={{border: "1px solid #ddd"}}>{listitems[i].max_bid_for_a_player}</td>
                            </tr>
                            
                            );
                          
                        }
                        return rows;
                      }

                    })()

                  }


                            
                        </tbody>
                    </table>
 </SoftBox>
  ):(<></>)}


<SoftBox display="flex" justifyContent="space-between" pb={0} alignItems="center" p={1}>
    
    <Swiper

modules={[Pagination, Navigation, Autoplay]}
spaceBetween={50}
slidesPerView={1}

autoplay={{ delay: 3000, disableOnInteraction: false }} // Auto slide every 3 seconds
      pagination={{ clickable: true }}

      loop={true}  
    >
      

      {this.state.top_sell && this.state.top_sell.length > 0 ? (
    (() => {
      const elements = [];
      
      for (let i = 0; i < this.state.top_sell.length; i++) {
        const bid = this.state.top_sell[i];
        const keyid = 20000+i;
        elements.push(
          <SwiperSlide key={keyid} >

<div class="col-12" style={{textAlign:'center'}}>Top Bids</div>

<div class="container mt-4">
        <div class="player-card">
            <img src={bid.profile_pic} alt="Player Image"/>
            <div class="player-info">
                <h5>{bid.full_name} <span class="badge bg-dark">{bid.sl_no}</span></h5>
            </div>
            <img src= {bid.team_logo ? (bid.team_logo) : (team2)} alt="Team Logo" class="team-logo"/>
        </div>
        <div class="sold-section">
            <div class="status">

           

                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.97 11.03a.75.75 0 0 0 1.08.022L12.03 7.08a.75.75 0 0 0-1.08-1.04L7.5 9.292 5.775 7.567a.75.75 0 1 0-1.05 1.05l2.243 2.243z"/>
                </svg>
                Sold to {bid.team_name}
            </div>
            <div class="price">for {bid.auction_sold_amount}</div>
        </div>
    </div>



           
          </SwiperSlide>
        );
      }
      return elements;
    })()
  ) : (
    <SwiperSlide>Top Bids</SwiperSlide>
  )}

    </Swiper>

    </SoftBox>




    <SoftBox display="flex" justifyContent="space-between" pb={0} alignItems="center" p={1}>
    
    <Swiper

modules={[Pagination, Navigation, Autoplay]}
spaceBetween={50}
slidesPerView={1}

autoplay={{ delay: 4000, disableOnInteraction: false }} // Auto slide every 3 seconds
      pagination={{ clickable: true }}

      loop={true}  
    >
      

      {this.state.recent_sell && this.state.recent_sell.length > 0 ? (
    (() => {
      const elements = [];
      
      for (let i = 0; i < this.state.recent_sell.length; i++) {
        const bid = this.state.recent_sell[i];
        const keyid = 20000+i;
        elements.push(
          <SwiperSlide key={keyid} >

<div class="col-12" style={{textAlign:'center'}}>Recent Bids</div>

<div class="container mt-4">
        <div class="player-card">
            <img src={bid.profile_pic} alt="Player Image"/>
            <div class="player-info">
                <h5>{bid.full_name} <span class="badge bg-dark">{bid.sl_no}</span></h5>
            </div>
            <img src= {bid.team_logo ? (bid.team_logo) : (team2)} alt="Team Logo" class="team-logo"/>
        </div>
        <div class="sold-section">
            <div class="status">

           

                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.97 11.03a.75.75 0 0 0 1.08.022L12.03 7.08a.75.75 0 0 0-1.08-1.04L7.5 9.292 5.775 7.567a.75.75 0 1 0-1.05 1.05l2.243 2.243z"/>
                </svg>
                Sold to {bid.team_name}
            </div>
            <div class="price">for {bid.auction_sold_amount}</div>
        </div>
    </div>



           
          </SwiperSlide>
        );
      }
      return elements;
    })()
  ) : (
    <SwiperSlide>Recent Bids</SwiperSlide>
  )}

    </Swiper>

    </SoftBox>

<SoftBox display="flex" justifyContent="space-between" pb={0} alignItems="center" p={1}>
Team Details:
</SoftBox>


<Grid  justifyContent="space-between"  alignItems="center" p={1} >
    
{
                  (() => {
                  const listitems = this.state.teams_details;
                 // console.log(listitems);
                  //console.log("populating")
                  if (typeof(listitems) != "undefined")
                  {
                  //console.log(listitems)
              const rows = [];
              for (let i = 0; i < listitems.length; i++) 
              {
                //team_name,  team_logo, current_wallet_balance, current_players_count, players
                  
                  rows.push(
            <Team sl_no={i} max_players_count={this.state.main_auction_details.team_max_playes_count} max_wallet_size={this.state.main_auction_details.team_max_pocket_size} linktoteam={listitems[i].team_hash_code} players={listitems[i].players} max_bid_for_a_player={listitems[i].max_bid_for_a_player} current_players_count={listitems[i].current_players_count} description={listitems[i].description} current_wallet_balance={listitems[i].current_wallet_balance} team_logo={listitems[i].team_logo} team_name={listitems[i].team_name} key={i} />
            );
              }
              return rows;
            }

            })()
        
          }

    </Grid>

    </Grid>
          </Grid>
        </SoftBox>


        <Grid item xs={12} md={12}>
          <a name="Pending"></a>
         {/* Pending Players Section */}
         <div className="pending-players mt-4">
  <h3>Pending Players</h3>
  <div className="col-md-12">
    {Array.isArray(this.state.pending_players) &&
      this.state.pending_players.map((player, index) => (
        <div
          key={index}
          className="col-md-12 mb-2 d-flex align-items-center bg-light rounded shadow-sm p-2"
        >
          <img
            src={player.profile_pic}
            alt={player.full_name}
            className="me-3"
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          />
          <div className="d-flex justify-content-between w-100">
            <h5 className="mb-0" style={{ flex: "2 0 30%" }}>
              {player.full_name}  <span style={{color:"red"}}>{player.auction_sold_status === '2' ? "*" : ""}</span>
            </h5>
            <p className="mb-0 text-center" style={{ flex: "1 0 20%" }}>
              {player.sl_no}
            </p>
            <p className="mb-0 text-end" style={{ flex: "1 0 20%" }}>
              {player.auction_sold_status === '2' ? "Unsold" : ""}
            </p>
          </div>
        </div>
      ))}
  </div>
</div>

</Grid>

<Grid  xs={12} md={12}>
<table width="100%">
    <tbody>
<tr>
                        <td>
                          Pending players:</td>
                        <td>
                          {this.state.main_auction_details.pending_count}
                        </td>
                        <td>
                          Unsold players:</td>
                        <td>
                          {this.state.main_auction_details.unsold_count}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Sold players:</td>
                        <td>
                          {this.state.main_auction_details.sold_count}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      </tbody>
                      </table>
        
</Grid>

    </Card>
    <ToastContainer />
    <Footer />
    
    </DashboardLayout>
    );
  }
}
export default LiveAuction;