/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import axios from 'axios';  
import React from 'react';  

// @mui material components
import Card from "@mui/material/Card";

import Parser from 'html-react-parser';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";


//import Modal from 'react-bootstrap/Modal';

// Data
//import membersTableData from "layouts/groups/data/membersTableData";

import SoftAvatar from "components/SoftAvatar";

// Images
import team2 from "assets/images/team-2.jpg";



export default class Miplain extends React.Component {  
  constructor(props) {
    super(props);
    this.setState({loggedIn: '1'});
    this.setState({showModal: false});
    this.setState({viewstatus: 'list'});

    this.state = { loggedIn: '1',showModal: false,viewstatus:'list',linkrows:[],groupname:'Friends Group',group_logo:team2,current_player_profile:'<h4>Player details</h4>'};
    
  }
  state = {  
    normalmembers: []  ,
    guestmembers:[]

  }  


   generateTable(data) {
    var table = '<table>';
    var rowsPerColumn = Math.ceil(data.length / 3); // Splitting into 3 columns
    var columnIndex = 0;

    for (var i = 0; i < rowsPerColumn; i++) {
        table += '<tr>';

        for (var j = 0; j < 3; j++) {
            var index = j * rowsPerColumn + i;

            if (index < data.length) {
                var row = data[index];
                table += '<td>' + row.sl_no + '</td>';
                table += '<td>' + row.player_name + '</td>';
            } else {
                table += '<td></td><td></td>'; // Empty cells for balance
            }
        }

        table += '</tr>';
    }

    table += '</table>';
    return table;
}

  componentDidMount() { 

    const post_data = { lo : 1};

    axios.post( global.config.app.backend_api_url + `login/sessionavailable`,post_data, { withCredentials: true }
    )  
      .then(res => {  
        console.log(res.data)
        var user_id = res.data.user_details.user_id;
        
        if (user_id !== undefined && user_id !== null && user_id !== 'null') 
        {
        }else
        {
          window.location.replace('/sign-in');
          //return;
        }
  
      });

    this.getbasicajax();

      

  }  

  getbasicajax()
  {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let invite_code = params.get('i');
    //alert(invite_code)
    const post_data = { invite_code: invite_code };
    
//      alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
  axios.post( global.config.app.backend_api_url + `matchschedule/getbyinvite_code`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      
      this.setState( {user_id: res.data.user_id,groupname:res.data.link_data[0].group_name,group_admin_flag : res.data.link_data[0].is_admin
        ,series_id : res.data.link_data[0].series_id,series_name : res.data.link_data[0].series_name
        ,match_date : res.data.link_data[0].match_date
        ,from_time : res.data.link_data[0].from_time
        ,to_time : res.data.link_data[0].to_time
        ,header_description : res.data.link_data[0].header_description
        ,footer_description : res.data.link_data[0].footer_description
        ,total_participants : res.data.link_data[0].total_participants
        ,total_waiting_list : res.data.link_data[0].total_waiting_list
        ,add_delete_permission_cutoff_time : res.data.link_data[0].add_delete_permission_cutoff_time
        ,group_id : res.data.link_data[0].group_id
        ,joined_flag : res.data.link_data[0].joined_flag
        ,remaining_slots : res.data.link_data[0].remaining_slots
        ,status_name : res.data.link_data[0].status_name
        ,group_logo : res.data.link_data[0].group_logo}
        );

        this.setState( {
          og_title : res.data.link_data[0].group_name + ' - '+res.data.link_data[0].series_name + ' - '+res.data.link_data[0].match_date,
          og_description : res.data.link_data[0].remaining_slots +' slots remaining at '+ res.data.link_data[0].group_name + ' - '+res.data.link_data[0].series_name + ' - '+res.data.link_data[0].match_date
        });

      const normalmembers = res.data.players_list;
      console.log("getting response "+res.data.players_list)
      this.setState({ normalmembers:normalmembers });  
       
    });
  }
 

  
  
  
  
  

  render() {  

    return (  
      <SoftBox>
      
      <SoftBox py={3}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            
            {this.state.groupname} 
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                

                <table class='table table-sm' width="100%">
                  <tr>
                    <td class='det0' width="25%">Series Name </td>
                    </tr><tr>
                    <td class='det1' width="70%">{this.state.series_name}</td>
                    </tr>
                    <tr>
                    <td class='det0'  >Date </td>
                    </tr><tr>
                    <td  class='det1'>{this.state.match_date}</td>
                    </tr>
                    <tr>
                    <td class='det0'>From Time </td>
                    </tr><tr>
                    <td  class='det1'>{this.state.from_time}</td>
                    </tr>
                   

                </table>
                </SoftBox>
              

      
        <SoftBox mb={3}>
        {
                (() => {
                  if(this.state.viewstatus === 'list' ){ 
                   return (
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Participants List</SoftTypography>
              
             



            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
{
                (() => {


var iloop ;
var html_table = "<table cellspacing='1' width='100%'>";
html_table  =   html_table +"<tr><td><b>Sl</b></td><td><b>Name</b></td><td><b>Mobile No</b></td></tr>";

if(this.state.normalmembers!= undefined)
{
const res = this.state.normalmembers;
  for(iloop=0; iloop < res.length; iloop++){
      const thisid = res[iloop].id;
      const g_user_fk = res[iloop].series_participant_reg_user_fk;
      const g_group_fk = res[iloop].group_fk;
      const series_participant_id= res[iloop].series_participant_id;
      const href_link = "/viewmembers/"+thisid;
      console.log(g_user_fk)
      html_table  =   html_table +"<tr><td>"+(res[iloop].sl_no)+"</td><td>"+res[iloop].player_name+"</td><td>"+res[iloop].mobile_no+"</td></tr>";
  
  }
}
html_table  =   html_table + "</table>";

//html_table  = this.generateTable(this.state.normalmembers);

  return(
<SoftTypography variant="h6">{Parser(html_table)}</SoftTypography>
    )}
        

        
      )()
        
        }

              


            </SoftBox>


            

          </SoftBox>
        )}
        

        
      })()
        
        }
        </SoftBox>

      
      </SoftBox>
      
      
    </SoftBox>
    )  
  }  
}  

