// src/PlayerCollage.js
import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

const CollageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const TeamHeading = styled.h2`
  margin: 20px 0;
  text-align: center;
`;

const PlayerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  width: 100%;

  @media (min-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

const PlayerCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const PlayerImage = styled.img`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  object-fit: cover;

  @media (min-width: 600px) {
    width: 200px;
    height: 200px;
  }

  @media (min-width: 900px) {
    width: 220px;
    height: 220px;
  }
`;

const PlayerName = styled.p`
  margin-top: 10px;
  font-size: 14px;

  @media (min-width: 600px) {
    font-size: 16px;
  }

  @media (min-width: 900px) {
    font-size: 18px;
  }
`;

const PlayerCollage = ({ seriesName, teamName, players ,teamLogo,auctionLink,leagueLogo,ourLogo}) => {
  return (
    <CollageContainer>
      <TeamHeading>
      <Link to={auctionLink}>{seriesName}</Link>
        <br></br>{teamName}



        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
  {/*
  {leagueLogo ? (
    <img
      src={leagueLogo}
      alt="League Logo"
      style={{ maxHeight: '200px', marginRight: '20px' }}
    />
  ) : null}
  */}
  
{teamLogo && (
          <>
            <br />
            <img src={teamLogo} alt={`${teamName} logo`} style={{ marginTop: '10px', maxHeight: '200px' ,maxWidth:'200px' }} />
          </>
        )}

   <img src={ourLogo} width="100px" alt="Logo 1" />


  </div>


      
        </TeamHeading>
      <PlayerGrid>
        {players.map((player) => (
          <PlayerCard key={player.sl_no}>
            <PlayerImage src={player.profile_pic} alt={player.full_name} />
            <PlayerName>{player.full_name}</PlayerName>
          </PlayerCard>
        ))}
      </PlayerGrid>
    </CollageContainer>
  );
};

export default PlayerCollage;
