/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import axios from 'axios';  
import React from 'react';  
import { useLocation, Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import SoftInput from "components/SoftInput";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Helmet } from "react-helmet";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import {useEffect, useState, useRef} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

// Data
//import membersTableData from "layouts/groups/data/membersTableData";

import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { Navigate } from "react-router-dom";
import SoftButton from "components/SoftButton";

import whatsapp_logo from "assets/images/logos/whatsapp.png";
import telegram_logo from "assets/images/logos/telegram.png";


function Author({ image, name, email }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column" >
        <SoftTypography variant="button" fontWeight="medium" color="info">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};
function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
};

export default class Matchschedules extends React.Component {  
  constructor(props) {
    super(props);
    this.setState({loggedIn: '1'});
    this.setState({showModal: false});
    this.setState({viewstatus: 'list'});



    this.state = { loggedIn: '1',showModal: false,viewstatus:'list',linkrows:[],groupname:'Friends Group Trivandrum',group_normal_count: 14, group_guest_count : 2};
//    this.setState({linkData:{rows:[]}});
    
  }
  state = {  
    normalmembers: []  ,
    guestmembers:[]

  }  

    
  componentDidMount() {  

    const post_data = { lo : 1};

    axios.post( global.config.app.backend_api_url + `login/sessionavailable`,post_data, { withCredentials: true }
    )  
      .then(res => {  
        console.log(res.data)
        var user_id = res.data.user_details.user_id;
        
        if (user_id !== undefined && user_id !== null && user_id !== 'null') 
        {
        }else
        {
          window.location.replace('sign-in');
          //return;
        }
  
      });

      
    axios.get( global.config.app.backend_api_url + `groups`)  
      .then(res => {  
        //const normalmembers = res.data;  
        const normalmembers = this.createMemberRows(res.data);
        console.log("getting response ")
        this.setState({ normalmembers });  

        const guestmembers = this.createMemberRows(res.data);
        console.log("getting response ")
        this.setState({ guestmembers });  


        

      });
      
     

  }  
    
 onClickGenerateLink = (e)=> {
  alert('API to be called');
    this.setState({viewstatus: 'invite_link'});
  }

  deleteRow(id, e){  
    console.log("deleted id : "+ id);
    console.log( this.state.normalmembers);
    const normalmembers = this.state.normalmembers.filter(item => item.id !== id);  
    this.setState({ normalmembers }); 
    /*
    axios.delete(`http://localhost:9000/groups/${id}`)  
      .then(res => {  
        console.log(res);  
        console.log(res.data);  
    
        const normalmembers = this.state.normalmembers.filter(item => item.id !== id);  
        this.setState({ normalmembers });  
      })  */
    
  }  

  membersTableColumns = {
    member_columns : [
      { name: "slno",align: "left" },
      { name: "membername",align: "left" },
      { name: "joinedon",align: "left" },
      { name: "action",align: "left" },
    ]
  }
  
  linkTableColumns = {
    links_columns: [
      { name: "invitelink", align: "left" },
      { name: "max_join_limit_with_link", align: "left" },
      { name: "current_join_count", align: "left" },
      { name: "link_expiry", align: "left" },
      { name: "action", align: "left" },
    ]
  };

  linkTableRows = {
    linkrows: [
      {
        invitelink: <Author image={team2} name="https://cricketgroups.in/sdfdsf"  />,
        max_join_limit_with_link: <Function job="10"  />,
        current_join_count: <Function job="1"  />,
        link_expiry: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            23/12/2023
          </SoftTypography>
        ),
        action: (
          <SoftBox>
          <SoftTypography
            component="a"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            Delete
          </SoftTypography>
          <SoftBox component="img" src={whatsapp_logo} alt="Share to whatsapp" width="24px" mt={1} />
          <SoftBox component="img" src={telegram_logo} alt="Share to telegram" width="24px" mt={1} />

</SoftBox>

        ),
      }
      
    ],
  };


   membersTableData = {
    columns: [
      { name: "slno", align: "left" },
      { name: "membername", align: "left" },
      { name: "joinedon", align: "left" },
      { name: "action", align: "left" },
    ],
  
    rows: [
      {
        slno : <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        1
      </SoftTypography>,
        groupname: <Author image={team2} name="Friends Group"  />,
        location: <Function job="Trivandrum"  />,
        memberscount: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            25
          </SoftTypography>
        ),
        createdon: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            12/11/2022
          </SoftTypography>
        ),
        action: (
          <SoftBox>
          <SoftTypography
            component="a"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            Delete
          </SoftTypography>
</SoftBox>

        ),
      }
      
    ],
  };

  createMemberRows(res)
{
  //loop here and create rows in soft UI rows
  console.log(res)
  const plainObj = [];

  var iloop ;
  for(iloop=0; iloop < res.length; iloop++){
      const thisid = res[iloop].id;
      const href_link = "/Matchschedules/"+thisid;
      //loopData += `<li>${data[i].name}</li>`
      const arrayLike = {
        groupname: (
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={team2} alt={res[iloop].groupname} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column" >
        <SoftTypography component="a" href={href_link}  variant="button" fontWeight="medium" color="info">
        {res[iloop].groupname}
        </SoftTypography>
        
      </SoftBox>
    </SoftBox>
),
        location: <Function job={res[iloop].location}  />,
        memberscount: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            25
          </SoftTypography>
        ),
        'createdon': res[iloop].createdon,
        'action' : (
          <SoftBox>
          <SoftTypography
            component="a"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            onClick={() => this.deleteRow(thisid)}
          >
            Delete
          </SoftTypography>
          <SoftBox component="img" src={whatsapp_logo} alt="Share to whatsapp" width="24px" mt={1} />

          

          </SoftBox>
          
        ),
        'id' : res[iloop].id
      };
      Array.prototype.push.call(plainObj, arrayLike);

  }
  
    //const myObjStr = JSON.stringify(plainObj);
    console.log("obj:")
    console.log(plainObj)
    console.log("res:")
    console.log(res)
    
    return plainObj;
  }

  
  


  render() {  

    const handleClose = () => {
      this.setState({showModal: false});
    }
    const handleShow = () => {
      this.setState({showModal: true});
    }

    if(this.state.loggedIn!=='1')
    {
      return <Navigate to="/sign-in" />
  
    };

  const { columns, rows } = this.membersTableData;

  const {links_columns} = this.linkTableColumns;

  const {linkrows} = this.linkTableRows;
  const {member_columns} = this.membersTableColumns;
  //const [list, setList] = useState([]);
  //const [actuallist, setActuallist] = useState([]);

    console.log("sess_data:");  
    console.log(this.state.loggedIn);  

    return (  
      <DashboardLayout>
      <Helmet>
      <title>Group Members | {global.config.app.name}</title>
      <meta
        name="description"
    content="Join Our Cricket Team - Experience the thrill of competitive cricket and be part of a supportive community. Sign up now and showcase your skills on the field."
      />
      <meta
        name="keywords"
        content="Cricket, team, join, competitive, skills, community, sign up, field"
      />
    </Helmet>
      <DashboardNavbar />
      <SoftBox py={3}>
      <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              {this.state.groupname} |&nbsp;&nbsp;&nbsp;&nbsp;
              {this.state.group_normal_count} Nomral Members |&nbsp;&nbsp;&nbsp;&nbsp;
              {this.state.group_guest_count} Guest Members
              </SoftBox>
      </Card>
      <SoftBox py={3}>

      </SoftBox>
        <SoftBox mb={3}>
        {
                (() => {
                  if(this.state.viewstatus === 'list' ){ 
                   return (
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Normal Group Members</SoftTypography>
              <SoftButton variant="gradient" size="small" color="info"  onClick={() => this.setState({viewstatus: 'create',member_type:'1',member_type_name:'Normal member'})}>
                Add a new Member
              </SoftButton>

            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={member_columns} rows={this.state.normalmembers} />
            </SoftBox>


            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Guest Group Members</SoftTypography>
              <SoftButton variant="gradient" size="small" color="info"  onClick={() => this.setState({viewstatus: 'create',member_type:'2',member_type_name:'Guest member'})}>
                Add a new Guest Member
              </SoftButton>

            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={member_columns} rows={this.state.guestmembers} />
            </SoftBox>

          </Card>
        )}
        else if(this.state.viewstatus === 'create' )
        { 
         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            New Member 
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" id="groupform">
            <SoftBox mb={2}>
              <SoftInput placeholder="Mobile No" name="mobile_no" id="mobile_no" inputProps={{ maxLength: 10}}  />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="text" name="member_name" id="member_name" placeholder="Member Name" inputProps={{ maxLength: 60}} />
            </SoftBox>
            
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="dark" fullWidth onClick={() => this.setState({viewstatus: 'list'})}>
                Add
              </SoftButton>
            </SoftBox>
            
          </SoftBox>

          <Link to="/Matchschedules?id=21">
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    Back to list
                  </SoftTypography>
                
              </Link>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <Link to="/Matchschedules?id=21">
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'invite_link'})}
                  >
                    Generate invite link for {this.state.member_type_name}
                  </SoftTypography>
                
              </Link>

        </SoftBox>

          </Card>
        
        )
        }

        else if(this.state.viewstatus === 'edit' )
        { 
         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Update Group 
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" id="groupform">
            <SoftBox mb={2}>
              Group Name
              <SoftInput placeholder="Name" name="group_name" id="group_name" inputProps={{ maxLength: 60}}  />
            </SoftBox>
            <SoftBox mb={2}>
              Location
              <SoftInput type="text" name="location" id="location" placeholder="Location" inputProps={{ maxLength: 100}} />
            </SoftBox>
            <SoftBox mb={2}>Upload Logo
              <SoftInput type="file" name="group_logo" id="group_logo" placeholder="Upload Logo"  />
            </SoftBox>

            <SoftBox mb={2}>Fetch Telegram Main Group Name
              <SoftInput type="text" name="main_telegram_chat_id" id="main_telegram_chat_id" placeholder="Fetch Telegram Main Group Name"  inputProps={{ maxLength: 30,readOnly:true}} />
            </SoftBox>
            <SoftBox mb={2}>Fetch Telegram Guest Group Name
              <SoftInput type="text" name="guest_telegram_chat_id" id="guest_telegram_chat_id" placeholder="Fetch Telegram Guest Group Name"  inputProps={{ maxLength: 30,readOnly:true}} />
            </SoftBox>
            
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="dark" fullWidth onClick={() => this.setState({viewstatus: 'list'})}>
                Update
              </SoftButton>
            </SoftBox>
            
          </SoftBox>

          <Link to="/groups">
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    Back to list
                  </SoftTypography>
                
              </Link>

        </SoftBox>

          </Card>
        
        )
        }

        else if(this.state.viewstatus === 'invite_link' )
        { 
         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Generate Invite Links
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>

        <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            > Generated Links
              <Table columns={links_columns} rows={linkrows} />
            </SoftBox>

            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            > 
            <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    Generate a new invite link
                  </SoftTypography>

            </SoftBox>

          <SoftBox component="form" role="form" id="groupform">
            <SoftBox mb={2}>
              <SoftInput placeholder="Max Limit" name="max_join_limit_with_link" id="max_join_limit_with_link" inputProps={{ maxLength: 4}}  />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="text" name="link_expiry" id="link_expiry" placeholder="Link Expiry Date" inputProps={{ maxLength: 10}} />
            </SoftBox>
            
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="dark" fullWidth onClick={this.onClickGenerateLink}>
                Generate
              </SoftButton>
            </SoftBox>
            
          </SoftBox>

          <Link to="/Matchschedules?id=21">
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    Back to members list
                  </SoftTypography>
                
              </Link>
              

        </SoftBox>

          </Card>
        
        )
        }

        
      })()
        
        }
        </SoftBox>
        
      </SoftBox>
      
      

      <Footer />
      
    </DashboardLayout>
    )  
  }  
}  

