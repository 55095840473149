import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "liveauction.css";

function LiveAuction() {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [data, setData] = useState(null);

  // Simulate fetching data
  const fetchData = () => {
    const fetchedData = {
      auctionDetails: {
        title: "Friends - Auction 33",
        teams: 10,
        walletSize: 8000,
        playersInAuction: 90,
        basePrice: 500,
        pendingPlayers: 22,
        soldPlayers: 4,
        unsoldPlayers: 0,
      },
      currentPlayer: {
        name: "John Doe",
        team: "Marvels",
        bid: 5000,
        thumbnail: "https://via.placeholder.com/150",
      },
      topBids: [
        { player: "Player A", team: "Marvels", bid: 12000 },
        { player: "Player B", team: "Titans", bid: 11000 },
        { player: "Player C", team: "Rangers", bid: 10000 },
        { player: "Player D", team: "Warriors", bid: 9500 },
        { player: "Player E", team: "Marvels", bid: 9000 },
      ],
      teams: [
        {
          name: "Marvels",
          logo: "https://via.placeholder.com/50",
          balance: 30000,
          players: [
            { name: "Player A", amount: 5000 },
            { name: "Player F", amount: 6000 },
          ],
        },
        {
          name: "Titans",
          logo: "https://via.placeholder.com/50",
          balance: 25000,
          players: [
            { name: "Player B", amount: 4500 },
            { name: "Player G", amount: 5500 },
          ],
        },
      ],
      pendingPlayers: [
        {
          name: "Player H",
          amount: "Not Bidded",
          profilePic: "https://via.placeholder.com/50",
        },
        {
          name: "Player I",
          amount: "Not Bidded",
          profilePic: "https://via.placeholder.com/50",
        },
      ],
    };
    setData(fetchedData);
  };

  // Fetch data initially
  useEffect(() => {
    fetchData();
  }, []);

  const openModal = (player) => {
    setModalData(player);
    setShowModal(true);

    // Auto-close modal after 10 seconds
    setTimeout(() => {
      setShowModal(false);
    }, 10000);
  };

  if (!data) return <p>Loading...</p>;

  return (
    <div className="container py-3">
      {/* Refresh Icon */}
      <div className="refresh-icon">
        <button
          className="btn btn-outline-secondary rounded-circle shadow"
          onClick={fetchData}
        >
          🔄
        </button>
      </div>

      {/* Auction Details Section */}
      <div className="auction-details mb-3 p-3 rounded shadow-sm bg-light">
        <h4 className="text-danger">{data.auctionDetails.title}</h4>
        <div className="row">
          <div className="col-6">
            <p>
              No. of Teams: <strong>{data.auctionDetails.teams}</strong>
            </p>
            <p>
              Team Wallet Size:{" "}
              <strong>${data.auctionDetails.walletSize}</strong>
            </p>
            <p>
              Pending Players:{" "}
              <strong>{data.auctionDetails.pendingPlayers}</strong>
            </p>
            <p>
              Sold Players: <strong>{data.auctionDetails.soldPlayers}</strong>
            </p>
          </div>
          <div className="col-6">
            <p>
              Players in Auction:{" "}
              <strong>{data.auctionDetails.playersInAuction}</strong>
            </p>
            <p>
              Player Base Price:{" "}
              <strong>${data.auctionDetails.basePrice}</strong>
            </p>
            <p>
              Unsold Players:{" "}
              <strong>{data.auctionDetails.unsoldPlayers}</strong>
            </p>
          </div>
        </div>
      </div>

      {/* Top Bids Scrolling News */}
      <div className="top-bids mt-3 p-2 bg-gradient  rounded shadow-sm">
        <marquee>
          {data.topBids.map((bid, index) => (
            <span key={index} className="me-4">
              <strong>{bid.player}</strong> sold to <strong>{bid.team}</strong>{" "}
              for <strong>${bid.bid}</strong>
            </span>
          ))}
        </marquee>
      </div>

      {/* Current Bid Section 
      <div className="current-player-highlight p-3 bg-gradient  rounded shadow-lg mt-4">
        <h2 className="text-center mb-3">Current Bid</h2>
        <div className="d-flex align-items-center">
          <img
            src={data.currentPlayer.thumbnail}
            alt={data.currentPlayer.name}
            className="rounded-circle me-3"
            style={{ width: "60px", height: "60px" }}
          />
          <div>
            <h4>{data.currentPlayer.name}</h4>
            <p>
              Team: <strong>{data.currentPlayer.team}</strong>
            </p>
            <p>
              Bid: <strong>${data.currentPlayer.bid}</strong>
            </p>
            <button
              className="btn btn-primary btn-sm"
              onClick={() =>
                openModal({
                  name: data.currentPlayer.name,
                  team: data.currentPlayer.team,
                  bid: data.currentPlayer.bid,
                  thumbnail: data.currentPlayer.thumbnail,
                })
              }
            >
              Show Details
            </button>
          </div>
        </div>
      </div>
    */}

      {/* Teams Section */}
      <div className="teams mt-4">
        <h3>Teams</h3>
        {data.teams.map((team, index) => (
          <div
            key={index}
            className={`team mb-3 p-3 rounded shadow-sm ${
              index % 2 === 0
                ? "bg-light-primary text-white1 bg-opacity-10"
                : "bg-light-success text-white1 bg-opacity-10"
            }`}
          >
            <div className="d-flex align-items-center mb-2">
              <img
                src={team.logo}
                alt={team.name}
                className="me-2"
                style={{ width: "50px", height: "50px" }}
              />
              <h4>{team.name}</h4>
              <span className="ms-auto">
                <strong>Balance:</strong> ${team.balance}
              </span>
            </div>
            <table className="table table-sm table-hover text-white1">
              <thead>
                <tr>
                  <th>Sl. No</th>
                  <th>Player</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {team.players.map((player, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{player.name}</td>
                    <td>${player.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>

      {/* Pending Players Section */}
      <div className="pending-players mt-4">
  <h3>Pending Players</h3>
  <div className="col-md-12">
    {data.pendingPlayers.map((player, index) => (
      <div
        key={index}
        className="col-md-12 mb-2 d-flex align-items-center bg-light rounded shadow-sm p-2"
      >
        <img
          src={player.profilePic}
          alt={player.name}
          className="me-3"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
        <div className="d-flex justify-content-between w-100">
          <h5 className="mb-0">{player.name}</h5>
          <p className="mb-0">Amount: {player.amount}</p>
        </div>
      </div>
    ))}
  </div>
</div>


      {/* Modal Popup */}
      {showModal && modalData && (
        <div className="modal fade show d-block" tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Player Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body text-center">
                <img
                  src={modalData.thumbnail}
                  alt={modalData.name}
                  className="rounded-circle mb-3"
                  style={{ width: "100px", height: "100px" }}
                />
                <h5>{modalData.name}</h5>
                <p>Team: {modalData.team}</p>
                <p>Bid: ${modalData.bid}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LiveAuction;
